import { useIsMobile } from "../../utils/hooks/useIsMobile";

export const statusChip = (status: string) => {
  let bg = "rgba(14, 76, 127, 0.1)";
  let color = "#0e4c7f";
  switch (status) {
    case "Identified": {
      bg = "#4FB29C50";
      color = "#16886E";
      break;
    }

    case "Contacted": {
      bg = "#0E4C7F30";
      color = "#0E4C7F";
      break;
    }

    case "Refused": {
      bg = "#AF405230";
      color = "#AF4052";
      break;
    }

    case "Pending Compliance": {
      bg = "#C9864C30";
      color = "#C9864C";
      break;
    }

    case "Onboarding": {
      bg = "#57728530";
      color = "#577285";
      break;
    }

    case "Confirmed": {
      bg = "#6B577330";
      color = "#6B5773";
      break;
    }

    case "Compliance Done": {
      bg = "#228B2230";
      color = "#228B22";
      break;
    }

    case "Pending Approval": {
      color = "#ec9324";
      bg = "#ec932430";
      break;
    }

    default: {
      console.log("No status Match , Going with default values of bg & color");
    }
  }
  const isMobile = useIsMobile();
  return {
    background: bg,
    padding: "3px 16px",
    borderRadius: "38px",
    letterSpacing: "0",
    color,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginLeft: isMobile ? "0.15em" : "0.75em",
    "& p": {
      fontSize: isMobile ? "0.50rem" : "0.75rem",
      textAlign: "center",
      fontWeight: "500",
    },
  };
};
