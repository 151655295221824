import { useEffect, useState } from "react";
import PageLayout from "../../../atoms/page-layout";
import { IconButton } from "@mui/material";
import { ArrowBack, ArrowForward } from "@mui/icons-material";
import { SelectedDrawer } from "./SelectedDrawer";
import PaginationComponent from "../../../atoms/pagination";
import { useNavigate, useSearchParams } from "react-router-dom";
import { SelectedMasterPageContent } from "./SelectedMasterPageContent";
import SelectedMasterPageHeader from "../../../molecules/app-bars/MastersList-page/masterslistpageheader";
import FullPageReview from "./FullPageReview";
import { useSnackbar } from "notistack";
import { MasterItem } from "../interfaces";
import { API_RESPONSES, URLS } from "../MastersCompany.constants";
const SelectedMaster = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const pageFromParams = parseInt(searchParams.get("page") || "1", 10);
  const [page, setPage] = useState(pageFromParams);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [totalPages, setTotalPages] = useState(1);
  const [totalResults, setTotalResults] = useState(0);
  const [masterCompanyId, setMasterCompanyId] = useState<number>(0);
  const [selectedState, setSelectedState] = useState<
    Record<number, MasterItem>
  >({});
  const [masterCompany, setMasterCompany] = useState<MasterItem | null>(null);
  const [counter, setCounter] = useState<number>(1);
  const [showReviewPanel, setShowReviewPanel] = useState<boolean>(false);
  const handlePageChange = (newPage: number) => {
    setPage(newPage);
  };
  const { enqueueSnackbar } = useSnackbar();
  const handleNextButton = () => {
    if (masterCompany?.label.trim() !== "") {
      setShowReviewPanel(true);
      return;
    }
    enqueueSnackbar(API_RESPONSES.NO_MASTER_COMPANY_LABEL, {
      variant: "error",
      autoHideDuration: 2000,
      anchorOrigin: { vertical: "bottom", horizontal: "left" },
    });
  };
  const handleRowsPerPageChange = (value: string) => {
    const newRowsPerPage = parseInt(value, 10);
    setRowsPerPage(newRowsPerPage);
    setPage(1);
  };
  const handleNavigateToPrevPage = async () => {
    if (showReviewPanel) {
      setShowReviewPanel(false);
      return;
    }
    navigate(URLS.MASTER_PAGE_URL);
  };
  useEffect(() => {
    const masterCompanyIdParam = searchParams.get("masterCompanyId");
    if (
      masterCompanyIdParam &&
      masterCompanyIdParam.trim() !== "" &&
      masterCompanyIdParam !== "null"
    ) {
      setMasterCompanyId(parseInt(masterCompanyIdParam));
    } else {
      navigate(URLS.MASTER_PAGE_URL);
    }
  }, []);
  return (
    <PageLayout>
      <SelectedMasterPageHeader />
      <SelectedMasterPageContent
        masterCompanyId={masterCompanyId}
        selectedState={selectedState}
        setSelectedState={setSelectedState}
        counter={counter}
        setCounter={setCounter}
        masterCompany={masterCompany}
        setMasterCompanyItem={setMasterCompany}
        page={page}
        setTotalPages={setTotalPages}
        setTotalResults={setTotalResults}
        rowsPerPage={rowsPerPage}
      />
      <SelectedDrawer
        masterCompanyId={masterCompanyId}
        selectedState={selectedState}
        setSelectedState={setSelectedState}
        counter={counter}
        setCounter={setCounter}
      />
      <FullPageReview
        masterCompanyId={masterCompanyId}
        selectedState={selectedState}
        showReviewPanel={showReviewPanel}
        masterCompany={masterCompany}
        setShowReviewPanel={setShowReviewPanel}
      />
      <div
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "space-between",
          alignItems: "center",
          gap: "2rem",
        }}
      >
        <IconButton
          onClick={handleNavigateToPrevPage}
          sx={{
            backgroundColor: "#EC9324",
            color: "white",
            "&:hover": {
              backgroundColor: "grey",
            },
            zIndex: 998,
          }}
        >
          <ArrowBack />
        </IconButton>
        <div>
          <PaginationComponent
            page={page}
            totalPages={totalPages}
            totalResult={totalResults}
            paginationHandler={(e, newPage) => handlePageChange(newPage)}
            dropdownFilterProps={{
              link: "",
              setFilterPayload: handleRowsPerPageChange,
              dropDownItems: [
                { label: "25", value: "25" },
                { label: "50", value: "50" },
                { label: "75", value: "75" },
                { label: "100", value: "100" },
              ],
              filterValue: rowsPerPage.toString(),
            }}
          />
        </div>
        <IconButton
          onClick={handleNextButton}
          sx={{
            backgroundColor: "#EC9324",
            color: "white",
            "&:hover": {
              backgroundColor: "grey",
            },
            zIndex: 998,
          }}
        >
          <ArrowForward />
        </IconButton>
      </div>
    </PageLayout>
  );
};
export default SelectedMaster;
