import { styled } from "@mui/material/styles";
import ToggleButtonGroup, {
  toggleButtonGroupClasses,
} from "@mui/material/ToggleButtonGroup";

export const StyledChipRadioToggle = styled(ToggleButtonGroup)(({ theme }) => ({
  boxShadow: "none",
  background: "transparent",
  padding: 0,
  flexWrap: "wrap",
  transitionDuration: "200ms",

  [`& .${toggleButtonGroupClasses.root}`]: {
    boxShadow: "none",
  },
  [`& .${toggleButtonGroupClasses.grouped}, .${toggleButtonGroupClasses.grouped}:not(:last-of-type), .${toggleButtonGroupClasses.grouped}:not(:first-of-type)`]: {
    padding: "0.25rem 0.5rem",
    margin: theme.spacing(0.75),
    border: `1px solid ${"grey"}`,
    borderRadius: "8px",
    transition: "all 200ms ease-in",
    fontSize: "0.75rem",
    textTransform: "none",
    color: "#252b3b",

    [`&.Mui-selected`]: {
      backgroundColor: theme.palette.primary.main,
      color: "white",
      border: `1px solid ${theme.palette.primary.main}`,
      "&:hover": {
        backgroundColor: theme.palette.primary.main,
      },
      [`&+.${toggleButtonGroupClasses.grouped}.Mui-selected`]:
        {
          margin: theme.spacing(0.5),
          border: `1px solid ${theme.palette.primary.main}`,
        },
    },
  },
}));

// .css-13zuvs1-MuiToggleButtonGroup-root .MuiToggleButtonGroup-grouped:not(:last-of-type)