import { Tooltip, IconButton, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import ProfileSection from '../../molecules/expert-profile-sections/profile-section/ProfileSection';
import PersonalInfoSection from '../../molecules/expert-profile-sections/personal-info-section/PersonalInfoSection';
import BasicDetailSection from '../../molecules/expert-profile-sections/basic-details-section/BasicDetailSection';
import AboutSection from '../../molecules/expert-profile-sections/about-section/AboutSection';
import ExperienceSection from '../../molecules/expert-profile-sections/experience-section/ExperienceSection';
import EducationSection from '../../molecules/expert-profile-sections/education-section/EducationSection';
import AwardSection from '../../molecules/expert-profile-sections/award-section/AwardSection';
import PublicationSection from '../../molecules/expert-profile-sections/publication-section/pubSection';
import PatentSection from '../../molecules/expert-profile-sections/patent-section/patent';
import WebHandleSection from '../../molecules/expert-profile-sections/web-handle-section/WebHandleSection';
import SnippetSection from '../../molecules/expert-profile-sections/snippet-section/SnippetSection';
import InternalInfoSection from '../../molecules/expert-profile-sections/internal-info-section/BasicDetailSection';
import DialogModal from '../dialog';
import AddPE from '../../organisms/project/project-add-pe-form';
import NoResultFoundFilters from '../noResultsFilters';
import ExpertProfileLoading from '../skeletons/exprtProfileSkeletons';
import { isClient, isInfollion, isExpert } from '../../utils/role';
import { alertNBackdropOpen, dialogState, getAllProfileDetails, handleClose, handleSubmitClose } from '../../organisms/expert-profile/helper';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
const QuickProfileReview = ({ expertId }: { expertId: number }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const is_add_bank_open = !!(searchParams.get("add_bank"));
  const [showElements, setShowElements] = useState({
    showAttachment: false,
    showTimeline: false,
    showBankDetails: is_add_bank_open,
  });
  const [alertNBackdrop, setAlertNBackdrop] = useState<alertNBackdropOpen>({
    alert: false,
    backdrop: false,
  });
  const [apiData, setApiData] = useState<any>(null);
  const [openDialog, setOpenDialog] = useState<dialogState>({
    uploadImage: false,
    deleteImage: false,
    showImage: false,
    addPE: { state: false, isChange: false },
    timelineFilters: { state: false },
    showProfileDialog: false,
  });
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get("id");
  const handleOpenProfileDialog = () => {
    setOpenDialog((prevState) => ({
      ...prevState,
      showProfileDialog: true,
    }));
    if (id || expertId) {
      setApiData(null);
      getAllProfileDetails(id || expertId, setApiData);
    }
  };
  const handleCloseProfileDialog = () => {
    setOpenDialog(prevState => ({
      ...prevState,
      showProfileDialog: false,
    }));
  };
  return (
    <div>
      <Tooltip title="View Profile" arrow>
        <AccountCircleIcon sx={{ color: '#bbbbbb', marginTop: '5.5px', marginLeft: '-2px' }} onClick={handleOpenProfileDialog} />
      </Tooltip>
      <DialogModal
        isOpen={openDialog.showProfileDialog}
        handleClose={handleCloseProfileDialog}
        title="Profile Sections"
      >
        {apiData ? (
          <>
            <ProfileSection
              showSection={showElements.showAttachment}
              showTimeline={showElements.showTimeline}
              apiData={apiData}
              handleShowImageDialogOpen={() =>
                setOpenDialog(prev => ({ ...prev, showImage: true }))
              }
            />
            {!isClient() && !isInfollion() && <PersonalInfoSection apiData={apiData} />}
            <BasicDetailSection apiData={apiData} />
            <AboutSection apiData={apiData} />
            <ExperienceSection apiData={apiData} />
            <EducationSection apiData={apiData} />
            <AwardSection apiData={apiData} />
            <PublicationSection apiData={apiData} />
            <PatentSection apiData={apiData} />
            {!isClient() && !isInfollion() && !showElements.showBankDetails && (
              <WebHandleSection apiData={apiData} />
            )}
            {!isExpert() && !isClient() && !isInfollion() && !showElements.showBankDetails && (
              <SnippetSection apiData={apiData} />
            )}
            {!isExpert() && !isClient() && !isInfollion() && (
              <InternalInfoSection apiData={apiData} />
            )}
            <DialogModal
              isOpen={openDialog.addPE.state}
              handleClose={() =>
                handleClose(
                  openDialog.addPE.isChange,
                  setAlertNBackdrop,
                  setOpenDialog
                )
              }
              title={isClient() ? "Request Call" : "Add to a Project"}
            >
              {apiData.dnd_enabled ? (
                <NoResultFoundFilters sx={{ marginBottom: "30px" }} text="Expert's DND on. Cannot add to projects." />
              ) : (
                <AddPE
                  handleClose={() =>
                    handleClose(openDialog.addPE.isChange, setAlertNBackdrop, setOpenDialog)
                  }
                  handleSubmitClose={() => handleSubmitClose(setOpenDialog)}
                  selectedExpert={id && apiData && { label: `ID: ${id}, ${apiData.name}`, value: parseInt(id) }}
                  isProjectField={true}
                  handleChangeForm={() => {
                    setOpenDialog(prev => {
                      if (!prev.addPE.isChange) {
                        return {
                          ...prev,
                          addPE: { ...prev.addPE, isChange: true },
                        };
                      }
                      return prev;
                    });
                  }}
                />
              )}
            </DialogModal>
          </>
        ) : (
          <ExpertProfileLoading />
        )}
      </DialogModal>
    </div>
  );
};

export default QuickProfileReview;