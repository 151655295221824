import React from 'react';

type PdfViewerProps = {
    pdfUrl: string;
    sx?: React.CSSProperties | undefined
};

const PdfViewer: React.FC<PdfViewerProps> = ({ pdfUrl , sx = {}}) => {
    return (
        <iframe
            src={pdfUrl}
            style={{ 
                width: '100%', 
                height: '100%', 
                border: 'none',
                overflow: 'hidden',  // Try to hide any overflow if necessary
                ...sx
            }}
            title="PDF Viewer"
            scrolling="no"  // Disable scrolling to avoid unnecessary scrollbars
        />
    );
};

export default PdfViewer;
