import { RequestServer } from "../../utils/services";
import { APIRoutes } from "../../constants";

export const quickSearch = async (page: number = 1, limit: number = 10, search: string = "") => {
  try {
    const payload = { page, limit, search };

    const response = await RequestServer(APIRoutes.ELASTICSEARCHURL, "POST", payload);

    if (response?.data) {
      return {
        data: response.data,
        total: response.total,
        page: response.page,
        totalPages: response.totalPages,
      };
    }
    return { data: [], total: 0, page: 1, totalPages: 100 };
  } catch (error) {
    console.error("Error:", error);
    return { data: [], total: 0, page: 1, totalPages: 100 };
  }
};
