import { FormProvider, useForm } from "react-hook-form";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useSnackbar } from "notistack";
import Fields from "./fields";
import { removeWhiteSpacesFromForm } from "../../../utils/utils";
import { defaultFormTheme } from "../../../atoms/defaultFormTheme";
import { useFullPageLoading } from "../../../atoms/full-page-loading/loadingContext";
import { APIRoutes } from "../../../constants";
import { RequestServer } from "../../../utils/services";
import DialogModal from "../../../atoms/dialog";

const RejectPanForm = (props: {
  bank_id: number;
  handleClose: () => void;
  refetch: () => Promise<void>;
  isOpen: boolean;
  document_type: string;
}) => {
  const { bank_id, handleClose, refetch, isOpen , document_type} = props;
  const { enqueueSnackbar } = useSnackbar();
  const { setLoading } = useFullPageLoading();

  const defaultValues = {
    reject_reason: { label: "", value: "" },
    reject_remarks: "",
  };

  const onSubmit = async (formData: typeof defaultValues) => {
    const newFormData = removeWhiteSpacesFromForm(formData, []);
    let reject_reason = newFormData.reject_reason.value

    if (newFormData.reject_remarks) {
      reject_reason = reject_reason ? reject_reason + " : " + newFormData.reject_remarks : newFormData.reject_remarks;
    }
    const payload = {
      action: "reject",
      bank_id,
      reject_reason: reject_reason,
    };

    try {
      setLoading(true);

      const response = await RequestServer(
        APIRoutes.manualVerifyPan,
        "POST",
        payload
      );

      if (response.success) {
        await refetch();
        enqueueSnackbar(response.message || document_type + " Rejected Successfully", {
          variant: "success",
        });
        handleClose();
      } else {
        enqueueSnackbar(
          response.message || response.error || "Error occurred!!",
          {
            variant: "warning",
          }
        );
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const methods = useForm({ defaultValues });

  const defaultTheme = createTheme(defaultFormTheme);

  return (
    <DialogModal title={"Reject " + document_type} handleClose={handleClose} isOpen={isOpen}>
      <FormProvider {...methods}>
        <ThemeProvider theme={defaultTheme}>
          <form onSubmit={methods.handleSubmit(onSubmit)} noValidate>
            <Fields handleClose={handleClose} document_type={document_type} />
          </form>
        </ThemeProvider>
      </FormProvider>
    </DialogModal>
  );
};

export default RejectPanForm;
