import { Box, Tooltip, Typography } from "@mui/material";
import { expertPrimaryTabStyle, primaryTabPStyle } from "./style";
import { paraStyles } from "../../molecules/expert-profile-sections/personal-info-section/styles";
import DoneIcon from '@mui/icons-material/Done';
import BoxCenter from "../boxCenter";

interface PrimaryTab {
  label: string;
  handlClick?: any;
  isEmail: boolean;
}

const ExpertPrimaryTab = (props: PrimaryTab) => {
  return (
    <Box
      onClick={() => {
        props.handlClick && props.handlClick();
      }}
      sx={
        props.isEmail
          ? { ...expertPrimaryTabStyle, cursor: "pointer" }
          : expertPrimaryTabStyle
      }
    >
      <Typography
        sx={paraStyles}
        component="p"
      >
        {props.label}
      </Typography>
      <Tooltip title="Primary" arrow>
        <Typography component="p" sx={primaryTabPStyle}>
          P
        </Typography>
      </Tooltip>
    </Box>
  );
};

interface BankTab {
  status: "Approved" | "Rejected" | "Pending" | null
  handlClick?: any;
}

export const ExpertBankTabs = (props: BankTab) => {
  const {status} = props;

  if(!status) {
    return <></>
  }

  return (
    <Box
      onClick={(event: any) => {
        event.stopPropagation();
        props.handlClick && props.handlClick();
      }}
      sx={
          { ...expertPrimaryTabStyle, cursor: "pointer" }
      }
    >
      <Tooltip title={status} arrow>
        <span>
          <BoxCenter sx={{...primaryTabPStyle,
            backgroundColor: status === "Rejected" ? "red" : status === "Approved" ? "green" : "var(--primary-color)",
          }}>
            {status === "Approved" ? <DoneIcon sx={{fontSize: 8}} /> : status === "Rejected" ? <p>R</p> : <p>P</p>}
          </BoxCenter>
        </span>
      </Tooltip>
    </Box>
  );
};

export default ExpertPrimaryTab;
