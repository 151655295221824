import Box from "@mui/material/Box";
import AppBarCommon from "../../app-bar-common";
import { useIsMobile } from "../../../utils/hooks/useIsMobile";

interface MastersListPageHeaderProps {
  selectedMasterCompany: number | null;
  selectedMasterCompanyLabel: string | null;
}

const MastersListPageHeader: React.FC<MastersListPageHeaderProps> = ({
  selectedMasterCompany,
  selectedMasterCompanyLabel,
}) => {
  const isMobile = useIsMobile();

  return (
    <Box sx={{ pb: isMobile ? "0" : "5px" }}>
      <AppBarCommon
        title={
          "Manage Companies" +
          (selectedMasterCompany
            ? ` - ${selectedMasterCompanyLabel} (${selectedMasterCompany})`
            : "")
        }
        isSearch={false}
        isUserIcon
        isIconDefine={false}
      />
    </Box>
  );
};

export default MastersListPageHeader;
