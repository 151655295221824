import {
  FormControl,
  FormControlProps,
  FormHelperText,
  Grid,
  GridProps,
} from "@mui/material";
import * as React from "react";
import {
  Controller,
  FieldValues,
  FormState,
  UseControllerProps,
} from "react-hook-form";

import { callAll } from "./util";
import CustomChipRadio from "../ChipRadio";

export interface HookChipRadioProps<T extends FieldValues = FieldValues>
  extends UseControllerProps<T> {
  gridProps?: GridProps;
  formControlProps?: FormControlProps;
  inputLabelProps?: React.LabelHTMLAttributes<HTMLLabelElement>;
  formHelperText?: string;
  formState: FormState<T>;
  label?: string;
  maxSelection?: number;
  options?: { label: string; value: string }[];
}

// ====================================================

/**
 *
 * @description A Select input field that uses react-hook-form to manage the form state.
 * @param {HookChipRadioProps}
 *
 * formState: The form state to use from the useHookForm hook that we are using.
 *
 * @returns {React.ReactElement}
 *
 */

// ====================================================

export const HookChipRadio = <T extends FieldValues>({
  gridProps,
  ...props
}: HookChipRadioProps<T>): React.ReactElement => {
  if (gridProps) {
    return (
      <Grid item {...gridProps}>
        <Component {...props} />
      </Grid>
    );
  }

  return <Component {...props} />;
};

/**
 *
 * @description The actual component that is returned from the HookSelect component
 *
 * @danger do not remove {formState: { errors }} from props as it is subscribed to the state
 *
 */
const Component = <T extends FieldValues>({
  formState: { errors },
  formControlProps = {},
  label,
  formHelperText,
  inputLabelProps,
  maxSelection,
  options,
  ...restC
}: HookChipRadioProps<T>) => {

  const { error } = restC?.control?.getFieldState(restC.name) ?? {};

  const { current: labelId } = React.useRef(Date.now().toString());

  return (
    <Controller
      {...restC}
      render={({
        field: {
          onChange: onChangeI,
          name,
          value = "",
          ref,
        },
      }) => (
        <FormControl fullWidth error={!!error} {...formControlProps}>
          <CustomChipRadio
            options={options}
            value={value}
            onChange={callAll((e: any) => {
              onChangeI(e);
            })}
            maxSelection={maxSelection}
          />
          <FormHelperText>{error?.message || formHelperText}</FormHelperText>
        </FormControl>
      )}
    />
  );
};
