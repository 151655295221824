import { EnqueueSnackbar } from "notistack";
import { APIRoutes } from "../../constants";
import { RequestServer } from "../../utils/services";
import { BankAccountValue } from "../payment-requests/types";

export const approveDocVerification = async ({
  bank_id,
  enqueueSnackbar,
  handleClose,
  setLoading,
  refetch
}: {
  bank_id: number;
  enqueueSnackbar: EnqueueSnackbar;
  handleClose: () => void;
  setLoading: (l: boolean) => void;
  refetch: () => Promise<void>;
}) => {
  setLoading(true);
  try {
    const payload = {
      action: "approve",
      bank_id,
    };

    const res = await RequestServer(APIRoutes.manualVerifyPan, "POST", payload);

    if (res.success) {
      enqueueSnackbar(res.message || "Pan Successfully Verified!!", {
        variant: "success",
      });
      handleClose();
      refetch();
    } else {
      enqueueSnackbar(res.message || res.error || "Something went wrong!!", {
        variant: "warning",
      });
    }
  } catch (err) {
    console.log(err);
  } finally {
    setLoading(false);
  }
};

export const rowsPerPage = [{ label: "9", value: "9" }];


export const getFormattedData = async (data: BankAccountValue[]): Promise<any> => {
  const expert_ids = data.map(d => d.fk_expert).join(",");

  const expert_response = await RequestServer(APIRoutes.getExpert + "?in___id=" + expert_ids + "&show_columns=id,name","GET");

  const expert_cards = expert_response.data;

  const final_data =  data.map(d => {
    const expert_card = expert_cards.find((e: any) => e.id === d.fk_expert);

    if(expert_card) {
      return {
        ...d,
        fk_expert_value: expert_card
      }
    } else {
      return {
        ...d
      }
    }
  });

  return final_data;
}