
import PageLayout from "../../atoms/page-layout";
import VerifyManualHeader from "../../molecules/app-bars/manual-verify";
import VerifyManualCards from "./verifyManualCards";


export default function ManualVerifyPage() {

    return (
        <PageLayout>
            <VerifyManualHeader />
            <VerifyManualCards />
        </PageLayout>

    )
}