import { NavbarActionsTypes } from "../../molecules/nav-bar-common/type";
import { isAdmin, isSuperAdmin } from "../../utils/role";

export const NavbarActions = (addToProjectClickHandler: () => void, addToStagingClickHandler: () => void): NavbarActionsTypes => {
  const Actions =
    isAdmin() || isSuperAdmin()
      ? [
          {
            title: "Add to Project",
            label: "+ Add to Project",
            onClick: addToProjectClickHandler,
          },
          {
            title: "Add to Staging",
            label: "+ Add to Staging",
            onClick: addToStagingClickHandler,
          },
        ]
      : [
          {
            title: "Add to Project",
            label: "+ Add to Project",
            onClick: addToProjectClickHandler,
          },
        ];

  return Actions;
};

export const NavbarActionsLength = NavbarActions(
  () => {},
  () => {}
).length;
