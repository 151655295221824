import { enqueueSnackbar, useSnackbar } from "notistack";
import React, { useState, useCallback, useMemo } from "react";
import { Tooltip, IconButton, Typography, Box, Divider, Grid, Checkbox, useMediaQuery, Link } from "@mui/material";
import PhoneCall from "../../assets/images/expert/call_expert.png";
import Envelope from "../../assets/images/expert/letter_expert.png";
import userTime from "../../assets/images/expert/user_expert.png";
import { selectedCardsTypes } from "../../pages/Experts/types";
import { toggleItemInArray } from "../../common/select";
import { useIsMobile } from "../../utils/hooks/useIsMobile";
import { statusChip } from "../../atoms/IndividualExpertCards/style";
import ContactMail from "../profile-cardV1/ContactMail";
import QuickProfileReview from "./QuickProfileReview";
type IndividualCardProps = {
  name: string;
  id: number;
  page: number;
  search: string;
  primary_email: string;
  primary_mobile: string;
  highlights: Record<string, any>;
  status: string;
  selectExpert: boolean;
  handleOpenAddExpert: () => void;
  handleOpenSE: () => void;
  selectedCards: any;
  totalSelected: number;
  setSelectedCards: any;
  approved_by_value: {
    name: string;
  };
};
const IndividualCards: React.FC<IndividualCardProps> = React.memo(
  ({
    name,
    id,
    primary_email,
    primary_mobile,
    approved_by_value,
    highlights,
    status,
    handleOpenAddExpert,
    handleOpenSE,
    selectExpert,
    selectedCards = [],
    setSelectedCards,
    page,
  }) => {
    const [expandedKeys, setExpandedKeys] = useState<Record<string, boolean>>({});
    const isMobile = useIsMobile();

    const toggleExpand = useCallback((key: string) => {
      setExpandedKeys((prev) => ({
        ...prev,
        [key]: !prev[key],
      }));
    }, []);

    const truncateText = useCallback((text: string, maxLength: number): string => {
      return text.length > maxLength ? `${text.slice(0, maxLength)}...` : text;
    }, []);

    const styleText = useCallback((text: string) => {
      const regex = /<em>(.*?)<\/em>/g;
      const parts = [];
      let lastIndex = 0;
      let match;
      while ((match = regex.exec(text)) !== null) {
        const [fullMatch, content] = match;

        parts.push(text.slice(lastIndex, match.index));
        parts.push(
          <span key={match.index} style={{ color: "#EC9324", backgroundColor: "yellow", fontWeight: "bold" }}>
            {content}
          </span>
        );
        lastIndex = match.index + fullMatch.length;
      }

      parts.push(text.slice(lastIndex));

      return parts;
    }, []);

    const styledHighlights = useCallback(
      (value: string) => {
        return styleText(value);
      },
      [styleText]
    );

    const highlightEntries = useMemo(() => {
      return Object.entries(highlights).map(([key, value]) => {
        const displayValue = Array.isArray(value) ? value.join(" ") : String(value);
        const isExpanded = expandedKeys[key] || false;
        const truncatedValue = truncateText(displayValue, 200);
        return {
          key,
          displayValue,
          truncatedValue,
          isExpanded,
        };
      });
    }, [highlights, expandedKeys, truncateText]);
    const toggleSelected = (selectedExpert: any) => {
      setSelectedCards(
        toggleItemInArray<{ label: string; value: number }>(selectedCards, {
          label: selectedExpert.name,
          value: Number(selectedExpert.id),
        })
      );
    };

    return (
      <Box sx={{ backgroundColor: "white", padding: 1, boxShadow: 1, borderRadius: 2, marginBottom: 2 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Box sx={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row', width: '100%' }}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <Tooltip title="Expert Name" arrow>
                <a
                  href={`/layout/expert-profile?id=${id}&page=${page}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ fontWeight: 'bold', fontSize: '13px', color: 'inherit', textDecoration: 'none' }}
                >
                  {name}
                </a>
              </Tooltip>
              <Tooltip title="ID" arrow>
                <Typography variant="body1" sx={{ color: "#EC9324", fontSize: '13px', fontWeight: '500' }}>
                  #{id}
                </Typography>
              </Tooltip>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: isMobile ? ' ' : '15px' }}>
              <Box sx={statusChip(status)}>
                <Typography component="p">{status}</Typography>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                {primary_email && primary_mobile && (
                  <ContactMail primary_email={primary_email} primary_mobile={primary_mobile} />
                )}
                {status === "Confirmed" && (
                  <Box sx={{ display: 'flex', gap: 1 }}>
                    <Tooltip title="PE+" arrow>
                      <IconButton onClick={handleOpenAddExpert}>
                        <Typography
                          component="span"
                          sx={{
                            backgroundColor: "#e7e9e8",
                            borderRadius: "50%",
                            color: "#14171F",
                            fontSize: "11.5px",
                            width: "24px",
                            height: "24px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          PE+
                        </Typography>
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="SE+" arrow>
                      <IconButton onClick={handleOpenSE}>
                        <Typography
                          component="span"
                          sx={{
                            backgroundColor: "#e7e9e8",
                            borderRadius: "50%",
                            color: "#14171F",
                            fontSize: "11.5px",
                            width: "24px",
                            height: "24px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          SE+
                        </Typography>
                      </IconButton>
                    </Tooltip>
                  </Box>
                )}
                <QuickProfileReview expertId={id} />
                {!isMobile && (
                  <Tooltip title="ApprovedBy" arrow>
                    <Typography variant="body1" sx={{ color: "black", fontWeight: "400", fontSize: "13px" }}>
                      {approved_by_value?.name}
                    </Typography>
                  </Tooltip>
                )}
              </Box>
            </Box>
          </Box>
          <Box>
            {selectExpert && status === "Confirmed" && (
              <Checkbox
                checked={selectedCards.some((card: any) => card.value === id)}
                onChange={() => toggleSelected({ name, id })}
                sx={{
                  '&.Mui-checked': {
                    color: '#ec9324',
                  },
                  '& .MuiSvgIcon-root': {
                    borderRadius: '4px',
                  },
                }}
              />
            )}
          </Box>
        </Box>
        <Divider style={{ margin: "3px 0", color: "black" }} />
        {highlightEntries.map(({ key, displayValue, truncatedValue, isExpanded }) => (
          <div key={key}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Typography
                  variant="body1"
                  component="span"
                  style={{
                    fontWeight: "bold",
                    fontSize: "13px",
                    display: "inline",
                  }}
                >
                  {key.replace(/_/g, " ")}:
                </Typography>

                <Typography
                  variant="body2"
                  component="span"
                  style={{
                    fontSize: "13px",
                    textAlign: "justify",
                    display: "inline",
                    marginLeft: "4px",
                  }}
                >
                  {isExpanded ? (

                    styledHighlights(displayValue)
                  ) : (
                    <>
                      <Typography variant="body2" component="span">
                        {styledHighlights(truncatedValue)}
                        {displayValue.length > 200 && (
                          <span
                            onClick={() => toggleExpand(key)}
                            style={{
                              color: "rgb(236, 147, 36)",
                              cursor: "pointer",
                              marginLeft: "4px",
                            }}
                          >
                            ...Read more
                          </span>
                        )}
                      </Typography>
                    </>
                  )}
                </Typography>
                {isExpanded && (
                  <Typography variant="body2" component="span">
                    <span
                      style={{
                        color: "rgb(236, 147, 36)",
                        cursor: "pointer",
                        marginTop: "4px",
                      }}
                      onClick={() => toggleExpand(key)}
                    >
                      Show less
                    </span>
                  </Typography>
                )}
              </Grid>
            </Grid>

            {isMobile && highlightEntries.length > 1 && (
              <Divider
                sx={{
                  marginY: 2,
                  width: '75vw',
                  borderStyle: "dashed",
                  backgroundColor: "#fafafa",
                  marginTop: '1px',
                  marginBottom: '0px'
                }}
              />
            )}
          </div>
        ))}
      </Box>
    );
  }
);

export default IndividualCards;
