import { Dispatch, SetStateAction } from "react";
import { CallDetails } from "../../../pages/Calls/types";
import { isGenerateInvoiceAllowed } from "../../../pages/Calls/helpers";
import { EnqueueSnackbar } from "notistack";
import { PrimaryBankValue } from "../../project/project-pe-mapping/actions/share-profile/email-format-dialog/types";
import { isExpert } from "../../../utils/role";
import { RequestServer } from "../../../utils/services";
import { APIRoutes } from "../../../constants";

export type SectionType = "Generate Invoice" | "Upload Invoice" | "Confirm call details" | "";
export type SetSectionType = Dispatch<SetStateAction<SectionType>>;

export type DialogTypes = {
  open_bank: {
    state: boolean;
  };
  submit: {
    state: boolean;
    handleSubmit: null | (() => Promise<void>);
  };
};

export type SetDialog = Dispatch<SetStateAction<DialogTypes>>;

export const defaultDialogValue: DialogTypes = {
  open_bank: {
    state: false,
  },
  submit: {
    state: false,
    handleSubmit: null,
  },
};

export const handleDialogClose = (setDialog: SetDialog) => {
  setDialog(() => defaultDialogValue);
};

export const checkboxDetails = (consent_for_higher_tds?: boolean) => [
  {
    name: "check_1",
    label: "Yes, I have checked everything. I am satisfied with the call details, bank A/C details and the amount payable.",
  },
  { name: "check_2", label: "I understand that once I raise payment request, I can not ask for any changes." },
  {
    name: "check_3",
    label:
      "I confirm that I have complied with the terms and conditions of the Expert Agreement and have not disclosed any confidential information.",
  },
  {
    name: "check_4",
    label: "I confirm that I will not disclose or attempt to benefit from any information revealed to me during the engagement",
  },
  ...(consent_for_higher_tds
    ? [
        {
          name: "check_5",
          label: "20% TDS will be deducted as you have not submitted a valid PAN matching with bank account holder name.",
        },
      ]
    : []),
];

export const hideAccountNumber = (account_number: string) => {
  if (account_number.length < 4) {
    return account_number;
  }

  return `${Array.from(new Array(account_number.length - 4), () => "X").join("")}${account_number.slice(-4)}`;
};

const doc_not_verified_error_msg = (doc_type: string) => {
  if (doc_type.toUpperCase() === "PAN") {
    return `Please review your bank account details & ${doc_type} before requesting for payment.`;
  }
  return `Please review your bank account details and upload ${doc_type} before requesting for payment.`;
};

export async function bankDetailsChecks({
  callDetails,
  enqueueSnackbar,
  setBankDetails,
  setDocError,
  setLoading,
  isOpen,
  setNoResult,
  setGenerateInvoiceAllowed,
  setSection,
}: {
  callDetails: CallDetails;
  enqueueSnackbar: EnqueueSnackbar;
  setBankDetails: Dispatch<SetStateAction<PrimaryBankValue | null>>;
  setDocError: Dispatch<SetStateAction<React.ReactNode | null>>;
  isOpen: boolean;
  setLoading: (loading: boolean) => void;
  setNoResult: Dispatch<SetStateAction<React.ReactNode | null>>;
  setGenerateInvoiceAllowed: React.Dispatch<React.SetStateAction<boolean>>;
  setSection: SetSectionType;
}) {
  try {
    const callDetail = callDetails[0];
    if (isOpen && callDetail) {
      setLoading(true);
      const bank_id = callDetail?.fk_expert_value.primary_bank_value?.id?.toString() || null;
      const { isAllowed, bankDetails } = await isGenerateInvoiceAllowed(
        callDetail?.fk_expert?.toString() || null,
        bank_id,
        enqueueSnackbar,
        true
      );

      const bank_country = bankDetails?.country;
      const indian_bank_account = bank_country === "India";
      const usa_bank_account = bank_country === "United States of America";

      const uploaded_documents = indian_bank_account
        ? bankDetails?.bank_details?.pan_url
        : usa_bank_account
        ? bankDetails?.bank_details?.w9_form_url
        : bankDetails?.bank_details.W8_BEN_E_url || bankDetails?.bank_details.W8_BEN_url;

      if (
        !bankDetails?.document_verified &&
        !bankDetails?.consent_for_higher_tds &&
        !bankDetails?.manual_verification &&
        (bankDetails?.bank_details.doc_verification === "manual_verify_rejected" || !uploaded_documents)
      ) {
        const document_type = indian_bank_account ? "PAN" : usa_bank_account ? "W-9" : "W-8BEN or W-8BEN-E";
        setBankDetails(bankDetails);
        setDocError(doc_not_verified_error_msg(bankDetails?.document_type || document_type));
      } else {
        if (isExpert()) {
          setGenerateInvoiceAllowed(isAllowed);
        }
        setBankDetails(bankDetails);
        setDocError(null);
        setSection("Confirm call details");
      }
      setLoading(false);
    } else if (callDetails.length === 0) {
      if (isExpert()) {
        const fkExpert = localStorage.getItem("expert_id");
        const request = await RequestServer(APIRoutes.scheduleCall + "?status=completed&fk_expert=" + fkExpert, "GET");
        if (request.data.length === 0) {
          setNoResult(`Payment request is already submitted for all the calls.`);
        } else {
          setNoResult(
            <span style={{ display: "flex", flexDirection: "column", alignItems: "flex-start", width: "100%" }}>
              <span style={{ fontSize: "18px", marginBottom: "1rem" }}>
                Please select calls with “Status = Completed” before requesting payment.
              </span>
              <span style={{ fontSize: "16px", marginBottom: "0.5rem" }}>
                • If no completed calls appear, use the status filter.
              </span>
              <span style={{ fontSize: "16px" }}>
                • The filter is located to the left of the ‘Submit Payment Request’ button.
              </span>
            </span>
          );
        }
      } else {
        setNoResult("Please select Completed Calls before requesting for Payments");
      }
      setLoading(false);
    }
    setLoading(false);
  } catch (error: any) {
    throw new Error(error);
  }
}