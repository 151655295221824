import { ThemeProvider, createTheme } from "@mui/material/styles";
import BankDetailsForm from "./add-bank-form";
import { useBoolean } from "../../utils/hooks/useBoolean";
import DialogModal from "../../atoms/dialog";
import Loading from "../../atoms/loading";
import { useState } from "react";
import { BankContext } from "./context";
import { DocVerification, WForm } from "./helper";

const CustomFontTheme = createTheme({
  typography: {
    fontFamily: ["Montserrat"].join(","),
    fontSize: 12,
  },
  palette: {
    primary: {
      main: "#EC9324",
    },
  },
});


type Props = {
  open: boolean;
  closeDialog: () => void;
  refresh: (() => Promise<void>) | (() => void);
  isEdit?: boolean;
  formDefaultValues?: any;
  showMainPageValue?: boolean;
  msmeUrl?: string;
  expert_id?: string;
}

export default function BankDetailsFormModal({ open, closeDialog, refresh, isEdit, formDefaultValues, showMainPageValue, msmeUrl , expert_id }: Props) {
  const { value: loading, setValue: setLoading } = useBoolean();
  const [msmeFileUrl, setMsmeUrl] = useState(msmeUrl || ""); // when bank in india, this is the url when user upload its msme certificate
  const [panUrl, setPanUrl] = useState(formDefaultValues?.pan_url || "");
  const [docVerification,setDocVerification] = useState<DocVerification>({state: formDefaultValues?.doc_verification ?? null, name_on_pan: formDefaultValues?.name_on_pan ?? null, manually_verified_pan_cards: formDefaultValues?.manually_verified_pan_cards || []});
  const [wForm,setWForm] = useState<WForm>({
    w9: formDefaultValues?.w9_form_url ?? null, 
    W8_BEN_url: formDefaultValues?.W8_BEN_url ?? null, 
    W8_BEN_E_url: formDefaultValues?.W8_BEN_E_url ?? null
  });
  const [controller, setController] = useState<any>({
    control: null,
    for: "",
    setSelectedFile: null,
  }); // abort controller

  return (
    <ThemeProvider theme={CustomFontTheme}>
      <DialogModal
        isOpen={open}
        handleClose={closeDialog}
        title={isEdit ? "Edit Bank Details" : "Add New Bank Details"}
      >
        <BankContext.Provider
          value={{
            controller,
            setController,
            msmeFileUrl,
            setMsmeUrl,
            setLoading,
            docVerification,
            setDocVerification,
            panUrl,
            setPanUrl,
            setWForm,
            wForm
          }}
        >
          <Loading loading={loading} controller={controller} />
          <BankDetailsForm
            refresh={refresh}
            handleClose={closeDialog}
            setLoading={setLoading}
            isEdit={!!isEdit}
            formDefaultValues={formDefaultValues}
            showMainPageValue={showMainPageValue}
            expert_id={expert_id}
          />
        </BankContext.Provider>
      </DialogModal>
    </ThemeProvider>
  );
}