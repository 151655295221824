import { FC } from "react";
import { useHookFormContext } from "../../../utils/hooks/useHookFormContext";
import BankDetailsIndia from "../bank-details-india";
import BankDetailsOthers from "../bank-details-others";

const FullDetailsForm = ({isEdit, individual_option}: {isEdit: boolean, individual_option: any}) => {
  const { watch } = useHookFormContext();

  const bank_country = watch("bank_country");

  return (
    <>{bank_country === "India" ? <BankDetailsIndia isEdit={isEdit} /> : <BankDetailsOthers country={bank_country} isEdit={isEdit} default_individual_option={individual_option} />} </>
  );
};

export default FullDetailsForm;
