import { FormProvider, useForm } from "react-hook-form";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useSnackbar } from "notistack";
import Fields from "./fields";
import { removeWhiteSpacesFromForm } from "../../../utils/utils";
import { defaultFormTheme } from "../../../atoms/defaultFormTheme";
import { useFullPageLoading } from "../../../atoms/full-page-loading/loadingContext";
import { APIRoutes } from "../../../constants";
import { RequestServer } from "../../../utils/services";
import DialogModal from "../../../atoms/dialog";
import { LocalDayjs } from "../../../utils/timezoneService";
import { isFutureDate } from "../../../organisms/expert-cards/helper";

const ApproveDocForm = (props: {
  bank_id: number;
  handleClose: () => void;
  refetch: () => Promise<void>;
  isOpen: boolean;
  document_type: string;
  bank_card: any;
}) => {
  const { bank_id, handleClose, refetch, isOpen , document_type, bank_card} = props;
  const { enqueueSnackbar } = useSnackbar();
  const { setLoading } = useFullPageLoading();

  const doc_submission_date = bank_card.bank_details.doc_submission_date || null;

  const defaultValues = {
    validity_date: doc_submission_date ? LocalDayjs(doc_submission_date).endOf('year').add(3, 'year') : null,
    check_valid: null
  };

  const onSubmit = async (formData: typeof defaultValues) => {
    const newFormData = removeWhiteSpacesFromForm(formData, []);

    if(!formData.check_valid) {
      enqueueSnackbar("Please verify the form details and validity date", {variant: "warning"});
      return;
    }

    if(!isFutureDate(formData.validity_date,"If the form has already expired then please 'Reject' the document",enqueueSnackbar)) {
      return;
    }

    const payload = {
      action: "approve",
      bank_id,
      validity_date: formData.validity_date?.format("YYYY-MM-DD")
    };

    try {
      setLoading(true);

      const response = await RequestServer(
        APIRoutes.manualVerifyPan,
        "POST",
        payload
      );

      if (response.success) {
        await refetch();
        enqueueSnackbar(response.message || document_type + " Rejected Successfully", {
          variant: "success",
        });
        handleClose();
      } else {
        enqueueSnackbar(
          response.message || response.error || "Error occurred!!",
          {
            variant: "warning",
          }
        );
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const methods = useForm({ defaultValues });

  const defaultTheme = createTheme(defaultFormTheme);

  return (
    <DialogModal title={"Approve " + document_type} handleClose={handleClose} isOpen={isOpen}>
      <FormProvider {...methods}>
        <ThemeProvider theme={defaultTheme}>
          <form onSubmit={methods.handleSubmit(onSubmit)} noValidate>
            <Fields handleClose={handleClose} document_type={document_type} />
          </form>
        </ThemeProvider>
      </FormProvider>
    </DialogModal>
  );
};

export default ApproveDocForm;
