import * as React from "react";

import ToggleButton from "@mui/material/ToggleButton";
import { useSnackbar } from "notistack";
import { StyledChipRadioToggle } from "./ChipRadio.styled";

type Props = {
  value: string;
  options?: { label: string; value: string }[];
  maxSelection?: number;
  onChange: (selectedValues: string) => void;
};

export default function CustomChipRadio({
  onChange,
  options,
  value,
  maxSelection = 1,
}: Props) {
  const isExclusive = maxSelection === 1;
  const realValue = isExclusive ? value :  !!value ? value.split(",") : [];
  const {enqueueSnackbar} = useSnackbar();


  const handleChange = (
    _event: React.MouseEvent<HTMLElement>,
    newSelectedValues: string[] | string
  ) => {
    if (newSelectedValues !== null && newSelectedValues !== undefined) {
      if (!Array.isArray(newSelectedValues)) {
        onChange(newSelectedValues);
      } else{
        if (newSelectedValues?.length <= maxSelection) {
          onChange(newSelectedValues.join(","));
        } else{
          enqueueSnackbar(`You can only select ${maxSelection} items`, {
            variant: "warning",
            autoHideDuration: 2000,
            anchorOrigin: { vertical: "bottom", horizontal: "left" },
          });
        }
      }
    } else{
      onChange("");
    }

  };

  return (
    <StyledChipRadioToggle exclusive={isExclusive} value={realValue} onChange={handleChange}>
      {(options || []).map((option) => (
        <ToggleButton
          value={option.value}
          key={option.value}
          aria-label={option.label}
        >
          {option.label}
        </ToggleButton>
      ))}
    </StyledChipRadioToggle>
  );
}
