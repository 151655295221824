import React, { BaseSyntheticEvent, useEffect, useState } from "react";
import {
  Drawer,
  Grid,
  Box,
  Button,
  TextField,
  InputAdornment,
  Tooltip,
  List,
  ListItem,
  Typography,
  IconButton,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import SearchIcon from "@mui/icons-material/Search";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { useIsMobile } from "../../../utils/hooks/useIsMobile";
import { isSuperAdmin } from "../../../utils/role";
import { SelectedDrawerProps } from "../interfaces";
import { STRINGS } from "../MastersCompany.constants";
interface MasterItem {
  label: string;
  id: number;
  division: string | undefined;
}
export const SelectedDrawer: React.FC<SelectedDrawerProps> = ({
  selectedState,
  setSelectedState,
  counter,
  setCounter,
}) => {
  const [showDrawer, setShowDrawer] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [localSearchList, setLocalSearchList] =
    useState<Record<number, MasterItem>>(selectedState);
  const isMobile = useIsMobile();
  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target?.value);
  };
  const updateLocalSearchList = async () => {
    if (searchValue === "" || searchValue.trim() === "") {
      setLocalSearchList(selectedState);
      return;
    }
    const keys = Object.keys(selectedState);
    const newLocalList: Record<number, MasterItem> = {};
    for (let index = 0; index < keys.length; index++) {
      const key = keys[index];
      if (
        selectedState[parseInt(key)].label
          .toLowerCase()
          ?.includes(searchValue.toLocaleLowerCase())
      ) {
        newLocalList[parseInt(key)] = selectedState[parseInt(key)];
      }
    }
    setLocalSearchList(newLocalList);
  };
  useEffect(() => {
    updateLocalSearchList();
  }, [searchValue, selectedState, counter]);
  const removeFromSelectedState = async (
    e: BaseSyntheticEvent,
    key: number
  ) => {
    setSelectedState((prev) => {
      delete prev[key];
      return prev;
    });
    setCounter((prev) => prev - 1);
  };
  return (
    <div>
      {!showDrawer && isSuperAdmin() && (
        <Tooltip title={STRINGS.OPEN_SELECTED_MASTERS} arrow>
          <Button
            sx={{
              color: "#000000",
              fontSize: "15px",
              fontFamily: "inherit",
              position: "fixed",
              right: "-3px",
              top: "40%",
              background: "#80808082",
              padding: isMobile ? "8px 0" : "8px",
              transition: "all 0.3s ease",
              "& .extra-content": {
                display: "none",
              },
              "&:hover .extra-content": {
                display: "inline-block",
              },
              "&:hover .chevron-icon": {
                marginRight: "10px",
              },
            }}
            onClick={() => setShowDrawer(true)}
          >
            <ChevronLeftIcon
              className="chevron-icon"
              sx={{ fontSize: "25px", marginBottom: "1px" }}
            />
          </Button>
        </Tooltip>
      )}
      <Drawer
        anchor="right"
        open={showDrawer}
        onClose={() => setShowDrawer(false)}
      >
        <Box
          sx={{
            position: "sticky",
            top: 0,
            zIndex: "10",
            padding: "20px",
            background: "#f5f5f5",
          }}
        >
          <TextField
            fullWidth
            value={searchValue}
            onChange={handleSearchChange}
            placeholder="Search..."
            variant="outlined"
            size="small"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </Box>
        <Box>
          <Typography align="center" variant="h6">
            Selected Companies ({(Object.keys(selectedState) || []).length})
          </Typography>
          <List style={{ width: "30vw" }}>
            {localSearchList && !Object.keys(localSearchList).length && (
              <Typography align="center">No companies</Typography>
            )}
            {localSearchList &&
              Object.keys(localSearchList).length > 0 &&
              Object.keys(localSearchList).map((id: string) => {
                const key: number = parseInt(id);
                return (
                  <ListItem
                    key={key}
                    style={{
                      padding: "5px 10px 5px 10px",
                      margin: "0px",
                      borderBottom: "1px solid #ccc",
                    }}
                  >
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          alignItems: "center",
                          gap: "1rem",
                        }}
                      >
                        <Typography>
                          {localSearchList[key].label} (
                          {localSearchList[key].id})
                        </Typography>
                        {localSearchList[key].division && (
                          <div
                            style={{
                              backgroundColor: "#A9A9A9",
                              borderRadius: "0.375rem",
                              border: "1px solid #A9A9A9",
                              marginLeft: "4px",
                              marginRight: "2px",
                              color: "#fff",
                              paddingLeft: "2px",
                              paddingRight: "2px",
                            }}
                          >
                            <Typography
                              style={{
                                maxWidth: "150px",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                              }}
                            >
                              {localSearchList[key]?.division
                                ? localSearchList[key].division
                                : ""}
                            </Typography>
                          </div>
                        )}
                      </div>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          height: "100%",
                        }}
                      >
                        <IconButton
                          onClick={(e) => {
                            removeFromSelectedState(e, key);
                          }}
                          sx={{
                            backgroundColor: "rgba(210,210,210 , 1)",
                            padding: "2px",
                            fontSize: "2px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            color: "white",
                            "&:hover": {
                              backgroundColor: "#ff474c",
                            },
                          }}
                        >
                          <Close
                            style={{
                              display: "block",
                              width: "15px",
                              height: "15px",
                            }}
                          />
                        </IconButton>
                      </div>
                    </div>
                  </ListItem>
                );
              })}
          </List>
        </Box>
        <Grid container spacing={2} sx={{ padding: 2 }}></Grid>
      </Drawer>
    </div>
  );
};
