import { Grid } from "@mui/material";
import FormCancelSubmitBtns from "../../../atoms/formCancelSubmitBtns";
import { HookCheckBox } from "../../../atoms/form-fields/SLFieldCheckBox";
import { useHookFormContext } from "../../../utils/hooks/useHookFormContext";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import HookDateTimePicker from "../../../atoms/form-fields/SLFieldDateTimePicker";
import HookDatePicker from "../../../atoms/form-fields/SLFieldDatePicker";

type Props = {
  handleClose: () => void;
  document_type: string;
};


const Fields = ({ handleClose ,document_type}: Props) => {
  const {registerState} = useHookFormContext();
  return (
    <Grid container spacing={3} mt={"5px"}>

      <Grid item xs={12}>
        <p style={{fontSize: "14px", fontWeight: "500"}}>Are you sure you want to approve this {document_type}?</p>
      </Grid>
      <Grid item xs={12}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <HookDatePicker
            {...registerState("validity_date")}
            rules={{
              required: { value: true, message: "This field is required" },
            }}
            datePickerProps={{
              className: "date-picker",
              label: "Validity Date",
              format: "DD MMM YYYY",
              disablePast: true,
              slotProps: {
                textField: { required: true, size: "small", fullWidth: true },
              },
            }}
          />
        </LocalizationProvider>
      </Grid>

      {/* Approve Reasons */}
      <Grid item xs={12}>
          <HookCheckBox
            {...registerState("check_valid")}
            label="I have properly checked the form and the validity date"
          />
      </Grid>

      {/* ACTION BUTTONS */}
      <FormCancelSubmitBtns handleClose={handleClose} />
    </Grid>
  );
};

export default Fields;
