import { List, Typography } from "@mui/material";
import React, { Fragment, useState } from "react";
import TableViewCommon from "../../../molecules/table-view-common";
import TableCellsRow from "./reviewTableCell";
import CustomBtnFilled from "../../../atoms/form-molecules/CustomBtnFilled";
import { submitCompanyDeduplication } from "./helper";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import { useFullPageLoading } from "../../../atoms/full-page-loading/loadingContext";
import {
  MasterItem,
  SubmitResponseType,
  FullPageReviewProps,
} from "../interfaces";
import { URLS, API_RESPONSES } from "../MastersCompany.constants";
const headCells = [
  {
    id: "id",
    label: "ID",
    numeric: false,
    disablePadding: false,
    isSort: false,
  },
  {
    id: "label",
    label: "Label",
    numeric: false,
    disablePadding: false,
    isSort: false,
  },
  {
    id: "division",
    label: "Division",
    numeric: false,
    disablePadding: false,
    isSort: false,
  },
];
const styles: any = {
  height: "calc(100vh  - 54px)",
  width: "100vw",
  position: "fixed",
  zIndex: "999",
  top: "54px",
  left: "0",
  backgroundColor: "white",
};
const parseData = (selectedState: Record<number, MasterItem>) => {
  return (Object.keys(selectedState) || []).map((key) => ({
    id: selectedState[parseInt(key)].id,
    label: selectedState[parseInt(key)].label,
    division: selectedState[parseInt(key)].division,
  }));
};
const FullPageReview: React.FC<FullPageReviewProps> = ({
  selectedState,
  showReviewPanel,
  masterCompany,
  setShowReviewPanel,
}) => {
  const { setLoading } = useFullPageLoading();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const handleSubmit = async () => {
    if (masterCompany === null) {
      enqueueSnackbar(API_RESPONSES.NO_MASTER_COMPANY_SELECTED, {
        variant: "error",
        autoHideDuration: 2000,
        anchorOrigin: { vertical: "bottom", horizontal: "left" },
      });
      navigate(URLS.MASTER_PAGE_URL);
      return;
    }
    const duplicateCompanies: MasterItem[] = [];
    (Object.keys(selectedState) || []).forEach((key) => {
      duplicateCompanies.push({
        ...selectedState[parseInt(key)],
      });
    });
    const response: SubmitResponseType = await submitCompanyDeduplication(
      masterCompany,
      duplicateCompanies
    );
    if (response.success) {
      enqueueSnackbar(response.message, {
        variant: "success",
        autoHideDuration: 2000,
        anchorOrigin: { vertical: "bottom", horizontal: "left" },
      });
      navigate(URLS.MASTER_PAGE_URL);
    } else {
      enqueueSnackbar(response.message, {
        variant: "error",
        autoHideDuration: 2000,
        anchorOrigin: { vertical: "bottom", horizontal: "left" },
      });
    }
    setLoading(false);
  };
  return (
    <Fragment>
      {showReviewPanel && (
        <div style={styles}>
          <Typography align="left" variant="h5" mt={"1rem"} ml={"1rem"}>
            Review Before Submission
          </Typography>
          <div
            style={{
              marginLeft: "1rem",
              marginTop: "0.5rem",
              display: "flex",
              justifyContent: "flex-start",
              gap: "1rem",
            }}
          >
            <Typography>Master Company :- </Typography>
            <Typography>
              {masterCompany?.label} ({masterCompany?.id})
            </Typography>
            <Typography>Division :- {masterCompany?.division}</Typography>
          </div>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <TableViewCommon<MasterItem>
              rows={parseData(selectedState)}
              totalSelected={0}
              title=""
              headCells={headCells}
              isSelected={false}
              selectAllowed={(row) => false}
              handleTableRowClick={(e, row) => {}}
              tableCells={(row, labelId, isSelected) => (
                <TableCellsRow
                  key={row.id}
                  row={row}
                  labelId={labelId}
                  isSelected={isSelected}
                />
              )}
              isItemSelected={(id) => false}
              handleSelectAllClick={(e) => {}}
              containerSx={{ maxHeight: "calc(100dvh - 255px)" }}
              outerBoxSx={{ mt: "5px" }}
              paperSx={{ borderRadius: "2px" }}
              rowsPerPageDefault={200}
              rowsPerPageOptions={[50, 100, 200, 400]}
            />
          </div>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              gap: "1rem",
            }}
          >
            <CustomBtnFilled
              label="Back"
              variant="contained"
              onClick={() => {
                setShowReviewPanel(false);
              }}
              sx={{
                width: 120,
                padding: "10px 16px",
                fontSize: "16px",
                backgroundColor: "#1976d2",
                "&:hover": {
                  backgroundColor: "#1565c0",
                },
              }}
            />
            <CustomBtnFilled
              label="Submit"
              variant="contained"
              onClick={() => {
                setLoading(true);
                handleSubmit();
              }}
              sx={{
                width: 120,
                padding: "10px 16px",
                fontSize: "16px",
                backgroundColor: "#1976d2",
                "&:hover": {
                  backgroundColor: "#1565c0",
                },
              }}
            />
          </div>
        </div>
      )}
    </Fragment>
  );
};
export default FullPageReview;
