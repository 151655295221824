import { Grid } from '@mui/material';
import DialogModal from '../../../atoms/dialog'
import FormCancelSubmitBtns from '../../../atoms/formCancelSubmitBtns';
import { FileUpload } from '../../../molecules/input-components/FileUpload';
import { useBankContext } from '../context';
import { useState } from 'react';
import { useSnackbar } from 'notistack';

type Props = {
    handleClose(): void;
    isOpen: boolean;
    handleSubmitClose(url: string): void;
    isPanUpload: boolean;    
    title?: string;
}

const UploadFiles = ({ handleClose, isOpen, handleSubmitClose, isPanUpload, title }: Props) => {
    const [url,setUrl] = useState("");
    const { setController, setLoading } = useBankContext();
    const {enqueueSnackbar} = useSnackbar();
    return (
        <DialogModal
            isOpen={isOpen}
            title={title || (isPanUpload ? "Upload PAN" : "Upload MSME Certificate")}
            handleClose={handleClose}
        >
            <Grid container mt={"20px"}>
                <FileUpload
                    setController={setController}
                    setLoading={setLoading}
                    setUrl={setUrl}
                    dropzoneConfig={{
                        text: <div>
                            <p>Drag 'n' drop file here, or click to select file</p>
                            <p>Accepted Formats - .jpeg .png .jpg .webp .pdf</p>
                        </div>
                    }}
                />
                <FormCancelSubmitBtns
                    handleClose={handleClose}
                    handleSubmitBtnClick={() => {
                        if(url) {
                            handleSubmitClose(url);
                            return;
                        } 

                        enqueueSnackbar("Please upload the file before submitting.", {variant: "warning"});
                    }}
                />

            </Grid>

        </DialogModal>
    )
}

export default UploadFiles