import { Dispatch, SetStateAction } from "react";

export type Dialog = {
  pan_image: { state: boolean };
  approve: { state: boolean };
  reject: { state: boolean };
  bank_details: {state: boolean};
};

export type SetDialog = Dispatch<SetStateAction<Dialog>>;

export const defaultDialogState: Dialog = {
  pan_image: { state: false },
  approve: { state: false },
  reject: { state: false },
  bank_details: {state: false}
};

export const handleClose = (setDialog: SetDialog) => {
  setDialog(() => defaultDialogState);
};

export const handleDialogOpen = ({
  setDialog,
  dialogName,
}: {
  setDialog: SetDialog;
  dialogName: "pan_image" | "approve" | "reject" | "bank_details";
}) => {
  setDialog((prev) => ({
    ...prev,
    [dialogName]: { ...prev[dialogName], state: true },
  }));
};
