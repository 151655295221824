import { BaseSyntheticEvent, useState } from "react";
import { useNavigate } from "react-router-dom";
import { IconButton } from "@mui/material";
import { ArrowForward } from "@mui/icons-material";
import PageLayout from "../../../atoms/page-layout";
import { MastersListPageContent } from "./MastersListPageContent";
import MastersListPageHeader from "../../../molecules/app-bars/MastersList-page";
import PaginationComponent from "../../../atoms/pagination";
import { useSnackbar } from "notistack";
import { API_RESPONSES, URLS } from "../MastersCompany.constants";
const MastersListPage = () => {
  const [page, setPage] = useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = useState<number>(25);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [totalResults, setTotalResults] = useState(0);
  const navigate = useNavigate();
  const [selectedMasterCompany, setSelectedMasterCompany] = useState<
    number | null
  >(null);
  const [selectedMasterCompanyLabel, setSelectedMasterCompanyLabel] = useState<
    string | null
  >(null);
  const handleRowsPerPageChange = (value: string) => {
    const newRowsPerPage = parseInt(value, 10);
    setRowsPerPage(newRowsPerPage);
    setPage(1);
  };
  const handlePageChange = (newPage: number) => {
    setPage(newPage);
  };
  const { enqueueSnackbar } = useSnackbar();
  const handleNavigateToNextPage = (e: any) => {
    if (!selectedMasterCompany) {
      enqueueSnackbar(API_RESPONSES.PLEASE_SELECT_MASTER_COMPANY, {
        variant: "error",
        autoHideDuration: 2000,
        anchorOrigin: { vertical: "bottom", horizontal: "left" },
      });
      return;
    }
    const url = `${URLS.SELECTED_PAGE_URL}${selectedMasterCompany}`;
    if (e?.ctrlKey || e?.metaKey) {
      const fullUrl = `${window.location.origin}${url}`;
      window.open(fullUrl, "_blank");
      return;
    }
    navigate(url);
  };
  return (
    <PageLayout>
      <MastersListPageHeader
        selectedMasterCompany={selectedMasterCompany}
        selectedMasterCompanyLabel={selectedMasterCompanyLabel}
      />
      <MastersListPageContent
        selectedMasterCompany={selectedMasterCompany}
        setSelectedMasterCompany={setSelectedMasterCompany}
        page={page}
        rowsPerPage={rowsPerPage}
        setTotalPages={setTotalPages}
        setTotalResults={setTotalResults}
        selectedMasterCompanyLabel={selectedMasterCompanyLabel}
        setSelectedMasterCompanyLabel={setSelectedMasterCompanyLabel}
      />
      <div
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "space-between",
          alignItems: "center",
          gap: "2rem",
        }}
      >
        <div style={{ visibility: "hidden" }}>
          <IconButton onClick={() => {}}>
            <ArrowForward />
          </IconButton>
        </div>
        <div>
          <PaginationComponent
            page={page}
            totalPages={totalPages}
            totalResult={totalResults}
            paginationHandler={(e, newPage) => handlePageChange(newPage)}
            dropdownFilterProps={{
              link: "",
              setFilterPayload: handleRowsPerPageChange,
              dropDownItems: [
                { label: "25", value: "25" },
                { label: "50", value: "50" },
                { label: "75", value: "75" },
                { label: "100", value: "100" },
              ],
              filterValue: rowsPerPage.toString(),
            }}
          />
        </div>
        <IconButton
          onClick={handleNavigateToNextPage}
          sx={{
            backgroundColor: "#EC9324",
            color: "white",
            "&:hover": {
              backgroundColor: "grey",
            },
            zIndex: 999,
          }}
        >
          <ArrowForward />
        </IconButton>
      </div>
    </PageLayout>
  );
};
export default MastersListPage;
