import Box from "@mui/material/Box";
import AppBarCommon from "../../app-bar-common";
import { useIsMobile } from "../../../utils/hooks/useIsMobile";

const SelectedMasterPageHeader = ({}) => {
  const isMobile = useIsMobile();

  return (
    <Box sx={{ pb: isMobile ? "0" : "5px" }}>
      <AppBarCommon
        title="Manage Companies"
        isSearch={false}
        isUserIcon
        isIconDefine={false}
      />
    </Box>
  );
};

export default SelectedMasterPageHeader;
