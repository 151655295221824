import * as React from "react";
import { ThemeProvider } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { CustomFontTheme, deleteBankDetails, getFormattedDetails, getbankDetails, setAsPrimary } from "./helper";
import styles from "./style.module.scss";
import CustomBtnFilled from "../../atoms/form-molecules/CustomBtnFilled";
import { useSnackbar } from "notistack";
import Loading from "../../atoms/loading";
import { isAdmin, isSuperAdmin } from "../../utils/role";
import BankDetailsFormModal from "../../organisms/add-bank-details-modal";
import { useBoolean } from "../../utils/hooks/useBoolean";
import { Link } from "react-router-dom";
import { BoxFlex } from "../../atoms/boxSpaceBtw";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';


type Props = {
  open: boolean;
  closeDialog: () => void;
  bankDetail: any;
  isPrimary: boolean;
  refresh: any;
  hideAllIcons?: boolean;
  expert_id?: string;
  defaultEditBankOpenValue?: boolean;
};

const getDocument = (bankDetail: any) => {
  if (bankDetail?.document_type === "W-9") {
    return bankDetail?.bank_details?.w9_form_url;
  }
  else if (bankDetail?.document_type === "PAN") {
    return bankDetail?.bank_details?.pan_url;
  }
  else if (bankDetail?.document_type === "W-8BEN") {
    return bankDetail?.bank_details?.W8_BEN_url;
  }
  else if (bankDetail?.document_type === "W-8BEN-E") {
    return bankDetail?.bank_details?.W8_BEN_E_url;
  }
  else 
    return null;
}

export default function BankDetails({
  open,
  closeDialog,
  bankDetail,
  isPrimary,
  refresh,
  hideAllIcons = false,
  expert_id,
  defaultEditBankOpenValue = false
}: Props) {
  const { enqueueSnackbar } = useSnackbar();
  const { value: isEditBankFormOpen, setFalse: closeEditBankForm, setTrue: openEditBankForm } = useBoolean(defaultEditBankOpenValue);
  const [loading, setLoading] = React.useState(false);

  async function setPrimary() {
    try {
      setLoading(true)
      const response = await setAsPrimary(bankDetail.id);

      if (response.success) {
        enqueueSnackbar("Bank account set as primary.", {
          variant: "success",
        });
        refresh()
        closeDialog();
      } else {
        if (response.error) {
          enqueueSnackbar(response?.error, { variant: "warning" });
        }

        if (response?.message) {
          enqueueSnackbar(response?.message?.toString(), {
            variant: "warning",
          });
        }
      }
      setLoading(false)
    } catch (err) {
      console.error({ err });
      enqueueSnackbar("Request failed.", { variant: "error" });
      setLoading(false)
    }
  }

  return (
    <>
      <ThemeProvider theme={CustomFontTheme}>
        <Dialog maxWidth={"sm"} open={open} onClose={closeDialog}>
          <Loading loading={loading} />
          <DialogTitle
            sx={{
              minWidth: "500px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
            className="dialog-header px-8 py-5"
          >
            <p>Bank Details</p>
            <IconButton aria-label="close" onClick={closeDialog}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent
            sx={{ paddingBottom: 0 }}
            className="add-project-modal-content gray-scroolbar"
          >
            <div className={styles.bankDetailsContainer}>
              <div className={styles.bankDetails}>
                <div>
                  <p className={styles.accountNumber}>
                    {bankDetail?.bank_details?.account_number}
                  </p>
                  <p className={styles.bankName}>
                    {bankDetail?.bank_details?.bank_name}
                  </p>
                </div>
              </div>
            </div>

            {/* <div className={styles.devider}></div>
          <div className={styles.container}>
            <div className={styles.toggle}>
              <p>Set As Primary</p>

              <Switch
                checked={checked}
                onChange={handleChange}
                inputProps={{ "aria-label": "controlled" }}
              />
            </div>
          </div> */}

            <div className={styles.devider}></div>

            <div className={styles.container}>
              <div className={styles.mainDetails}>
                {getFormattedDetails(bankDetail, isPrimary).map((data) => {

                  if (!data.value) return <></>;

                  if (data.label === "MSME Certificate") {
                    return <>
                      <div className={styles.row}>
                        <div className={styles.label}>{data.label}</div>
                        <Link target="_blank" rel="noopener noreferrer" to={data.value} className={styles.valueLink}>View</Link>
                      </div>
                    </>
                  }

                  const verification_status = data.label === "Pan Card No" ? data.value.split("___")[1] : "";

                  return (
                    <div className={styles.row}>
                      
                      {data.label === "Pan Card No" ? <>
                        {bankDetail?.pan &&
                          <>
                            <div className={styles.label}>{data.label}</div>
                            <div className={styles.value}>{data.value.split("___")[0]}</div>
                          </>}
                        {
                          verification_status === "Verified"
                            ?
                            <>
                              <div className={styles.label}>
                                <BoxFlex sx={{ color: "green", gap: '0.2rem', "& p": { fontSize: "14px", fontWeight: "500" } }}>
                                  <CheckCircleIcon sx={{ fontSize: "14px" }} />
                                  <p>{bankDetail?.document_type} Verified</p>
                                </BoxFlex>
                              </div>
                              { getDocument(bankDetail) &&
                              <div className={styles.value}>
                                <Link target="_blank" rel="noopener noreferrer" to={getDocument(bankDetail)} className={styles.valueLink}>View</Link>
                              </div>}
                            </>


                            :
                            verification_status === "higher_tds" ?
                              <>
                                <BoxFlex sx={{ color: "red", gap: '0.2rem', "& p": { fontSize: "14px", fontWeight: "500" } }}>
                                  <p>Consent For 20% TDS Deduction</p>
                                </BoxFlex>
                              </>
                              :
                              bankDetail?.bank_details?.doc_verification === "manual_verify_rejected" ?
                                <>
                                  <div className={styles.label}>
                                    <BoxFlex sx={{ color: "red", gap: '0.2rem', "& p": { fontSize: "14px", fontWeight: "500" } }}>
                                      <HighlightOffIcon sx={{ fontSize: "14px" }} />
                                      <p>{bankDetail?.document_type} Verification Rejected</p>
                                    </BoxFlex>
                                  </div>
                                  { getDocument(bankDetail) &&
                                  <div className={styles.value}>
                                    <Link target="_blank" rel="noopener noreferrer" to={getDocument(bankDetail)} className={styles.valueLink}>View</Link>
                                  </div>}
                                </>
                                :
                                verification_status === "verification_pending" ?
                                  <>
                                    <div className={styles.label}>
                                      <BoxFlex sx={{ color: "red", gap: '0.2rem', "& p": { fontSize: "12px", fontWeight: "500" } }}>
                                        <PendingActionsIcon />
                                        <p>{bankDetail?.document_type} Verfication Pending</p>
                                      </BoxFlex>
                                    </div>
                                    { getDocument(bankDetail) &&
                                    <div className={styles.value}>
                                      <Link target="_blank" rel="noopener noreferrer" to={getDocument(bankDetail)} className={styles.valueLink}>View</Link>
                                    </div>}
                                  </>
                                  : <></>
                        }
                      </> : <>
                        <div className={styles.label}>{data.label}</div>
                        <div className={styles.value}>{data.value}</div></>}
                    </div>
                  );
                })}
              </div>
            </div>
          </DialogContent>

          {
            !isAdmin() && !hideAllIcons &&
            <DialogActions className={styles.actions}>
              {isSuperAdmin() &&
                <CustomBtnFilled
                  label="Delete"
                  variant="Contained"
                  styles={{
                    backgroundColor: "#FD5C63",
                    fontWeight: "600"
                  }}
                  onClick={async () => {
                    await deleteBankDetails(getbankDetails(bankDetail, isPrimary), enqueueSnackbar, refresh,
                      closeDialog)
                  }}
                />
              }

              {!bankDetail?.document_verified &&
                <CustomBtnFilled
                  label="Edit Bank Details"
                  variant="outlined"
                  onClick={openEditBankForm}
                />
              }

              {isPrimary ? null : (
                <>
                  <CustomBtnFilled
                    label="Set as primary account"
                    variant="outlined"
                    onClick={setPrimary}
                  />
                </>
              )}
            </DialogActions>
          }


        </Dialog>
      </ThemeProvider>

      {/* Edit Bank Details */}
      {isEditBankFormOpen &&
        <BankDetailsFormModal
          open={isEditBankFormOpen}
          closeDialog={() => {
            closeEditBankForm()
            closeDialog()
          }}
          refresh={refresh}
          isEdit
          formDefaultValues={getbankDetails(bankDetail, isPrimary)}
          showMainPageValue
          msmeUrl={bankDetail.bank_details.msme_certificate || ""}
          expert_id={expert_id}
        />
      }
    </>
  );
}
