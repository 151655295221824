import TableCell from "@mui/material/TableCell";
import { MasterItem } from "../interfaces";
type Props = {
  row: MasterItem;
  labelId: string;
  isSelected: boolean;
};
const TableCellsRow = ({ row, labelId, isSelected }: Props) => {
  return (
    <>
      <TableCell
        key={"id-" + row.id.toString()}
        component="th"
        id={labelId}
        scope="row"
        padding="none"
        sx={{ paddingLeft: isSelected ? "0" : "1rem" }}
      >
        {row.id}
      </TableCell>
      <TableCell align="left" key={"label-" + row.id.toString()}>
        {row.label}
      </TableCell>
      <TableCell align="left" key={"div-" + row.id.toString()}>
        {row.division}
      </TableCell>
    </>
  );
};

export default TableCellsRow;
