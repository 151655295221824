import { Box } from "@mui/material";
import AppBarCommon from "../../app-bar-common";
import { useNavigate } from "react-router-dom";
import { AppRoutes } from "../../../constants";
import DropDownDrawerWithChip from "../../dropdown-drawer-with-chip";
import DropDownFilter from "../../../atoms/drop-down-filter";
import { useGetParams } from "../../../utils/hooks/useGetParams";
import { useIsMobile } from "../../../utils/hooks/useIsMobile";

export const getManualVerifyFilterUrl = (expert_id: string | null, doc_type: string,page: string) => `${AppRoutes.MANUAL_VERIFICATION}?page=${page}${expert_id ? "&expert_id=" + expert_id : ""}${doc_type ? "&doc_type=" + doc_type : ""}`

export default function VerifyManualHeader() {
  const doc_type = useGetParams("doc_type") || "";
  const expert_id = useGetParams("expert_id");
  const navigate = useNavigate();
    return (
      <Box sx={{ pb: "24px" }}>
        <AppBarCommon
          title={"Pending Verifications"}
          isUserIcon
          isSearch={true}
          searchValue={expert_id || ""}
          searchLabel="Search by Expert ID"
          onSearch={(query) => {
            navigate(getManualVerifyFilterUrl(query,doc_type,"1"));
          }}
          leftComponent={<>
            <LeftComponent doc_type={doc_type} expert_id={expert_id} />
          </>}
          isSearchLeftComponent={true}
          isIconDefine={false}
        />
      </Box>
    );
  }

  const options =  [
    { label: "All Documents", value: "" },
    { label: "PAN", value: "PAN" },
    { label: "W-9", value: "W-9" },
    { label: "W-8BEN", value: "W-8BEN" },
    { label: "W-8BEN-E", value: "W-8BEN-E" }
  ];
  

  function LeftComponent({doc_type, expert_id}: {doc_type: string,expert_id: string | null}) {
    const isMobile = useIsMobile();
    const navigate = useNavigate();

    return (
      <>
      {isMobile ?
        <DropDownDrawerWithChip
            chipLabel="Document Type"
            isClearDisabled
            value={doc_type}
            listArr={options}
            onItemSelect={(doc_type: string | null) => {
               navigate(getManualVerifyFilterUrl(expert_id,doc_type || "","1"));
            }}
        />
        :
        <>
            <DropDownFilter
                setFilterPayload={(doc_type: string) => {
                  navigate(getManualVerifyFilterUrl(expert_id,doc_type || "","1"));
                }}
                dropDownItems={options}
                filterValue={doc_type}
                noMinWidth
            />
        </>}
      </>
    )
  }
