import { Grid } from "@mui/material";
import { BankAccountValue } from "../../pages/payment-requests/types";
import FormCancelSubmitBtns from "../../atoms/formCancelSubmitBtns";
import ProfilePictureDialog from "../../atoms/profile-picture-dialog";

import { useState } from "react";
import {
  defaultDialogState,
  Dialog,
  handleClose,
  handleDialogOpen,
} from "./helper";
import WarningDialog from "../form-close-warning";
import { approveDocVerification } from "../../pages/manual-verification/helper";
import { useFullPageLoading } from "../../atoms/full-page-loading/loadingContext";
import { useSnackbar } from "notistack";
import RejectPanForm from "../../pages/manual-verification/about";
import { Link } from "react-router-dom";
import { AppRoutes } from "../../constants";
import ApproveDocForm from "../../pages/manual-verification/approve-form";
import CustomBtnFilled from "../../atoms/form-molecules/CustomBtnFilled";
import BankDetails from "../bank-details-modal";

type Props = {
  data: any;
  refetch: () => Promise<void>;
};

const paraStyle = (isBold: boolean = false) => ({
  fontSize: "12px",
  fontWeight: isBold ? "500" : "initial",
});

export default function VerifyManualCard({ data, refetch }: Props) {
  const [dialog, setDialog] = useState<Dialog>(defaultDialogState);
  const { enqueueSnackbar } = useSnackbar();
  const { setLoading } = useFullPageLoading();

  const handleDialogClose = () => handleClose(setDialog);
  // @ts-ignore
  const doc_url = data.bank_details?.pan_url || data.bank_details?.w9_form_url || (data.bank_details?.is_individual === "yes" ? data.bank_details?.W8_BEN_url : data.bank_details?.is_individual === "no" ? data.bank_details?.W8_BEN_E_url : null);
  // @ts-ignore
  const name_on_pan = data.bank_details?.name_on_pan || "-";
  // @ts-ignore
  const document_type = data.document_type || null;

  const is_pdf = doc_url ? (doc_url as string).includes(".pdf") : false;

  return (
    <>
      <div
        style={{ background: "white", overflowX: "hidden", paddingTop: "0" }}
        className={`card`}
      >
        <div
          onClick={() =>
            handleDialogOpen({ setDialog, dialogName: "pan_image" })
          }
          style={{ backgroundColor: "#1b1f23", cursor: "pointer" }}
        >
          {doc_url ? (
            <>
            {is_pdf ?  <iframe
            src={doc_url}
            style={{ 
                width: '100%', 
                height: '400px', 
                border: 'none',
                overflow: 'hidden'  // Try to hide any overflow if necessary
            }}
            title="PDF Viewer"
            scrolling="no"  // Disable scrolling to avoid unnecessary scrollbars
        /> : 
              <img
              src={doc_url}
              style={{
                width: "100%",
                objectFit: "contain",
                height: "400px",
              }}
            />}
            </>
          ) : <div style={{
            width: "100%",
            objectFit: "contain",
            height: "400px",
          }}></div>}
        </div>
        <hr />
        <div
          className={`wrapper card-padding`}
          style={{
            display: "flex",
            flexDirection: "column",
            position: "relative",
            marginTop: "10px",
          }}
        >
          <Grid container spacing={2}>
            <Grid item container xs={12}>
            <Grid item xs={4}>
                <p style={paraStyle(true)}>Expert:</p>
              </Grid>
              <Grid item xs={8}>
                <p style={paraStyle()}><Link target="_blank" to={AppRoutes.EXPERT_PROFILE + "?id=" + data?.fk_expert + "&page=1"}><span style={{color: "var(--green-color)", fontWeight: "500", cursor: "pointer"}}>{data?.fk_expert}</span></Link> / {data?.fk_expert_value?.name}</p>
              </Grid>
              {document_type === "PAN" &&
                <>
                  <Grid item xs={4}>
                    <p style={paraStyle(true)}>As per PAN:</p>
                  </Grid>
                  <Grid item xs={8}>
                    <p style={paraStyle()}>{name_on_pan || "-"} / <span>{data.pan}</span></p>
                  </Grid>
                </>
              }
              <Grid item xs={4}>
                <p style={paraStyle(true)}>A/C holder:</p>
              </Grid>
              <Grid item xs={8}>
                <p style={paraStyle()}>{data.account_holder_name}, <span onClick={
                  () => handleDialogOpen({ setDialog, dialogName: "pan_image" })
                } style={{...paraStyle(), color: "var(--green-color)", fontWeight: "500", cursor: "pointer"}}>{document_type === "W-9" ? "W-9 Form" : document_type}</span></p>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <CustomBtnFilled
                label= {"⁠View A/C Details"}
                variant="outlined"
                onClick={() => {
                  handleDialogOpen({ setDialog, dialogName: "bank_details" })
                }}
                styles={{color:"gray"}}
              />
            </Grid>
            <Grid item container xs={6}>
              <FormCancelSubmitBtns
                handleClose={() => {
                    handleDialogOpen({setDialog, dialogName: "reject"})
                }}
                handleSubmitBtnClick={() =>
                  handleDialogOpen({ setDialog, dialogName: "approve" })
                }
                cancelLabel="Reject"
                submitLabel="Approve"
              />
            </Grid>
          </Grid>
        </div>
      </div>

      {doc_url && (
        <ProfilePictureDialog
          handleClose={handleDialogClose}
          image={doc_url}
          is_pdf={is_pdf}
          isOpen={dialog.pan_image.state}
          openDeleteDialog={() => {}}
          openEditDialog={() => {}}
          showOnlyImage
          grid_sx={{margin: is_pdf ? "0" : "5rem"}}
          title={data?.fk_expert_value?.name + " | " + (document_type || "PAN")}
        />
      )}

      {dialog.approve.state && (
        data.document_type === "PAN" ? (
          <WarningDialog
            open={dialog.approve.state}
            text={`Are you sure you want to approve this ${data.document_type}?`}
            handleClose={handleDialogClose}
            handleYesClick={async () => {
              await approveDocVerification({
                bank_id: data.id,
                enqueueSnackbar,
                handleClose: handleDialogClose,
                setLoading,
                refetch,
              });
            }}
          />
        ) : 
        <ApproveDocForm 
        isOpen={dialog.approve.state}
        handleClose={handleDialogClose}
        bank_id={data.id}
        refetch={refetch}
        document_type={data.document_type}
        bank_card={data}
      />
      )}

      {dialog.reject.state && (
        <RejectPanForm
          bank_id={data.id}
          handleClose={handleDialogClose}
          refetch={refetch}
          isOpen={dialog.reject.state}
          document_type={data.document_type}
        />
      )}

      <BankDetails
        open={dialog.bank_details.state}
        closeDialog={handleDialogClose}
        bankDetail={data}
        isPrimary={false}
        refresh={() => {}}
        hideAllIcons
      />
    </>
  );
}
