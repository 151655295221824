import { Grid } from "@mui/material";
import FormCancelSubmitBtns from "../../../atoms/formCancelSubmitBtns";
import BasicAutocomplete from "../../../molecules/autocompletes/basic-autocomplete";
import { inputRowCommonStyles } from "../../../common/formStyles";
import { RequiredTextField } from "../../../molecules/input-components/RequiredTextField";

type Props = {
  handleClose: () => void;
  document_type: string;
};

const reject_reasons = [
    {
      label: "Uploaded document is unclear or blurry",
      value: "Uploaded document is unclear or blurry"
    },
    {
      label: "Details in the document do not match the bank account information",
      value: "Details in the document do not match the bank account information"
    },
    {
      label: "Document shared is outdated or expired",
      value: "Document shared is outdated or expired"
    },
    {
      label: "Incorrect document uploaded",
      value: "Incorrect document uploaded"
    },
    {
      label: "FTIN not applicable – Please share any valid government-issued ID number",
      value: "FTIN not applicable – Please share any valid government-issued ID number"
    },
    {
      label: "Blank document submitted",
      value: "Blank document submitted"
    },
    {
      label: "Signature or date is missing on the document",
      value: "Signature or date is missing on the document"
    },
    {
      label: "Mandatory details (name, address, citizenship, etc.) are missing",
      value: "Mandatory details (name, address, citizenship, etc.) are missing"
    },
    {
      label: "FTIN (Foreign Tax Identification Number) is missing",
      value: "FTIN (Foreign Tax Identification Number) is missing"
    }
]

const Fields = ({ handleClose ,document_type}: Props) => {
  return (
    <Grid container spacing={3} mt={"5px"}>

      <Grid item xs={12}>
        <p style={{fontSize: "14px", fontWeight: "500"}}>Are you sure you want to reject this {document_type}?</p>
      </Grid>
      {/* Reject Reasons */}
      <Grid item container xs={12}>
        <Grid item xs={12} sx={{ position: "relative" }}>
          <BasicAutocomplete
            label="Reason for rejection"
            registerName="reject_reason"
            isRequired
            options={reject_reasons}
          />
        </Grid>
      </Grid>
      <Grid item xs={12} sx={inputRowCommonStyles}>
                          <RequiredTextField
                              id="reject_remarks"
                              label="Remarks"
                              max={100}
                              required={false}
                          />
                      </Grid>
      {/* ACTION BUTTONS */}
      <FormCancelSubmitBtns handleClose={handleClose} />
    </Grid>
  );
};

export default Fields;
