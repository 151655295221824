import { Grid } from "@mui/material";
import FormCancelSubmitBtns from "../../../atoms/formCancelSubmitBtns";
import BasicAutocomplete from "../../../molecules/autocompletes/basic-autocomplete";
import { inputRowCommonStyles } from "../../../common/formStyles";
import { RequiredTextField } from "../../../molecules/input-components/RequiredTextField";

type Props = {
  handleClose: () => void;
  document_type: string;
};

const reject_reasons = [
  {
    label: "Document scan is not clear",
    value: "Document scan is not clear"
  },
  {
    label: "Details in document not matching with bank A/C",
    value: "Details in document not matching with bank A/C"
  },
  {
    label: "Old/Expired document",
    value: "Old/Expired document"
  },
  {
    label: "Wrong document uploaded",
    value: "Wrong document uploaded"
  },
  {
    label: "Mandatory Details missing",
    value: "Mandatory Details missing"
  }
]

const Fields = ({ handleClose ,document_type}: Props) => {
  return (
    <Grid container spacing={3} mt={"5px"}>

      <Grid item xs={12}>
        <p style={{fontSize: "14px", fontWeight: "500"}}>Are you sure you want to reject this {document_type}?</p>
      </Grid>
      {/* Reject Reasons */}
      <Grid item container xs={12}>
        <Grid item xs={12} sx={{ position: "relative" }}>
          <BasicAutocomplete
            label="Reason for rejection"
            registerName="reject_reason"
            isRequired
            options={reject_reasons}
          />
        </Grid>
      </Grid>
      <Grid item xs={12} sx={inputRowCommonStyles}>
                          <RequiredTextField
                              id="reject_remarks"
                              label="Remarks"
                              max={100}
                              required={false}
                          />
                      </Grid>
      {/* ACTION BUTTONS */}
      <FormCancelSubmitBtns handleClose={handleClose} />
    </Grid>
  );
};

export default Fields;
