import CircularProgress from "@mui/material/CircularProgress";
import Backdrop from "@mui/material/Backdrop";
import loadingGif from "../../public/loading.gif";

const BackdropComponent = ({ isBackdrop }: { isBackdrop: boolean }) => {
  return (
    <Backdrop sx={{ color: "#fff", zIndex: 2000 }} open={isBackdrop}>
      <CircularProgress color="inherit" />
    </Backdrop>
  );
};

export const BackgroundGifComponent = ({ isBackdrop }: { isBackdrop: boolean }) => {
  return (
    <Backdrop sx={{ color: "var(--primary-color)", zIndex: 2000 }} open={isBackdrop}>
       <img 
        src={loadingGif}
        alt="Loading" 
        style={{ width: 100, height: 100, borderRadius: "50%", margin: '10px'}} 
      />
    </Backdrop>
  );
};

export default BackdropComponent;
