import { APIRoutes } from "../../../constants";
import { RequestServer } from "../../../utils/services";
import {API_RESPONSES} from "../MastersCompany.constants";
import { MasterItem  , ProbableResult} from "../interfaces";
export const fetchMastersList = async (
  type: string,
  id: number
): Promise<ProbableResult> => {
  try {
    const response = await RequestServer(
      `${APIRoutes.MASTERS_URL}?type=${type}&id=${id}`,
      "GET"
    );
    if (response?.success && Array.isArray(response.data?.data)) {
      const resultObject: ProbableResult = {
        data: response.data.data,
        success: true,
        masterCompany: response.data.masterCompany,
      }
      return resultObject;
    }
    throw new Error(API_RESPONSES.INVALID_API_RESPONSE);
  } catch (error: any) {
    return {data:[],success:false,masterCompany:null};
  }
};
export const submitCompanyDeduplication = async (masterCompany:MasterItem,duplicateCompanies:MasterItem[])=>{
  const data = {
    masterCompany:masterCompany,
    duplicateCompanies:duplicateCompanies
  }
  try{
    const response = await RequestServer(`${APIRoutes.MASTERS_DEDUP}`,"POST",data);
    if(response?.success){
      return response;
    }
    throw new Error(response?.message);
  }catch(error:any){
    return {success:false , message:error.message};
  }
}
