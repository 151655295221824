import { createContext, useContext } from "react";
import { SetDialogOpenTypes } from "./helper";
import { Admins } from "../../organisms/admin/types";

export type AdminContextTypes = {
  setDialogOpen: SetDialogOpenTypes;
  adminData: Admins | null;
  loading: boolean;
  refetchAdminData: () => Promise<void>;
};

export const AdminContext = createContext<AdminContextTypes | null>(null);

export const useAdminContext = () => {
  const context = useContext(AdminContext);

  if (!context) {
    throw new Error(
      "useAdminContext must be used within a LoginContextProvider"
    );
  }

  return context;
};
