import React, { BaseSyntheticEvent, useEffect, useState } from "react";
import { fetchMastersList } from "./helper";
import styles from "./SelectedMaster.module.scss";
import {
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Input,
  Typography,
} from "@mui/material";
import SearchBar from "../../../molecules/app-bar-common/search-bar";
import { fetchCompanySearchList } from "../MastersList/helper";
import CustomBtnFilled from "../../../atoms/form-molecules/CustomBtnFilled";
import {
  MasterItem,
  ProbableResult,
  SearchResultType,
  SelectedMasterPageContentProps,
} from "../interfaces";
import { API_RESPONSES, API_PARAMS } from "../MastersCompany.constants";
export const SelectedMasterPageContent: React.FC<
  SelectedMasterPageContentProps
> = ({
  masterCompanyId,
  selectedState,
  setSelectedState,
  counter,
  setCounter,
  masterCompany,
  setMasterCompanyItem,
  page,
  setTotalPages,
  setTotalResults,
  rowsPerPage,
}) => {
  const [masters, setMasters] = useState<MasterItem[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [search, setSearch] = useState<string | null>(null);
  const [searchLoading, setSearchLoading] = useState<boolean>(false);
  const [searchError, setSearchError] = useState<string | null>(null);
  const [searchMastersData, setSearchMastersData] = useState<MasterItem[]>([]);
  const [selectedAll, setSelectedAll] = useState<boolean>(false);
  const [searchSelectedAll, setSearchSelectedAll] = useState<boolean>(false);
  const [totalProbableSelected, setTotalProbableSelected] = useState<number>(0);
  const [totalSearchSelected, setTotalSearchSelected] = useState<number>(0);
  const [totalProbableCount, setTotalProbableCount] = useState<number>(0);
  const [totalSearchCount, setTotalSearchCount] = useState<number>(0);
  const handleSearchHelper = async (searchString: string) => {
    setSearch(searchString);
    try {
      const result: SearchResultType = await fetchCompanySearchList(
        searchString,
        page,
        rowsPerPage
      );
      if (!result?.data?.length) {
        setSearchError(API_RESPONSES.NO_COMPANIES_FOUND);
        return;
      }
      setSearchMastersData(result?.data || []);

      if (result?.total && result?.limit) {
        setTotalResults(result.total);
        setTotalPages(Math.ceil(result.total / result.limit));
      }
    } catch (err) {
      setSearchError(API_RESPONSES.ERROR_FETCHING_MASTERS_LIST);
    } finally {
      setSearchLoading(false);
    }
  };
  useEffect(() => {
    if (search) {
      setSearchLoading(true);
      handleSearchHelper(search);
    }
  }, [page, rowsPerPage]);
  const handleSearch = async (searchString: string) => {
    setSearchLoading(true);
    setSearchError(null);
    handleSearchHelper(searchString);
  };
  const calculateProbableCounts = async () => {
    let length = masters.length;
    let totalCount = 0;
    let selectedCount = 0;
    for (let index = 0; index < length; index++) {
      if (masters[index]?.id === masterCompanyId) {
        continue;
      }
      if (selectedState[masters[index]?.id]?.id === masters[index]?.id) {
        selectedCount++;
      }
      totalCount++;
    }
    setTotalProbableSelected(selectedCount);
    setTotalProbableCount(totalCount);
  };
  const calculateSearchCounts = async () => {
    let length = searchMastersData.length;
    let totalCount = 0;
    let selectedCount = 0;
    for (let index = 0; index < length; index++) {
      if (searchMastersData[index]?.id === masterCompanyId) {
        continue;
      }
      if (
        selectedState[searchMastersData[index]?.id]?.id ===
        searchMastersData[index]?.id
      ) {
        selectedCount++;
      }
      totalCount++;
    }
    setTotalSearchSelected(selectedCount);
    setTotalSearchCount(totalCount);
  };
  const calculateCounts = async () => {
    calculateProbableCounts();
    calculateSearchCounts();
  };
  const handleCheckedChange = async (
    e: BaseSyntheticEvent,
    key: number,
    item: MasterItem,
    probable: boolean = false
  ) => {
    if (e.target.checked == true) {
      setSelectedState((prev) => {
        prev[key] = item;
        return prev;
      });
      setCounter((prev) => prev + 1);
    } else {
      setSelectedState((prev) => {
        delete prev[key];
        return prev;
      });
      setCounter((prev) => prev - 1);
    }
    calculateCounts();
  };
  useEffect(() => {
    const type = API_PARAMS.SEARCH_PROBABLE;
    const id = masterCompanyId;
    if (!id) {
      return;
    }
    const loadMasters = async () => {
      try {
        const fetchedMasters: ProbableResult = await fetchMastersList(type, id);
        if (fetchedMasters.success) {
          setMasters(fetchedMasters.data);
          setMasterCompanyItem(fetchedMasters.masterCompany);
        }
      } catch (error) {
        setError(API_RESPONSES.ERROR_FETCHING_MASTERS_LIST);
      } finally {
        setLoading(false);
      }
    };
    setLoading(true);
    loadMasters();
  }, [masterCompanyId]);
  useEffect(() => {
    if (!searchMastersData?.length) {
      setTotalSearchSelected(0);
      return;
    }
    let selectedSearchResultCount = 0;
    let allSelected = true;
    const length = searchMastersData.length;
    let totalCount = 0;
    for (let index = 0; index < length; index++) {
      if (searchMastersData[index]?.id === masterCompanyId) {
        continue;
      }
      totalCount++;
      if (
        selectedState[searchMastersData[index]?.id]?.id !==
        searchMastersData[index]?.id
      ) {
        allSelected = false;
        continue;
      }
      selectedSearchResultCount++;
    }
    setTotalSearchSelected(selectedSearchResultCount);
    setTotalSearchCount(totalCount);
    setSearchSelectedAll(allSelected);
  }, [searchMastersData]);
  useEffect(() => {
    calculateCounts();
  }, [masters, searchMastersData]);
  const checkedOrNot = (item: MasterItem): boolean => {
    return item.id === selectedState[item?.id]?.id;
  };
  const handleDivisionChange = async (
    e: BaseSyntheticEvent,
    item: MasterItem
  ) => {
    setSelectedState((prev) => {
      prev[item?.id] = {
        id: item.id,
        label: item.label,
        division: e.target.value,
      };
      return prev;
    });
    setCounter((prev) => prev + 1);
  };
  const handleMasterDivisionChange = async (e: BaseSyntheticEvent) => {
    setMasterCompanyItem((prev) => {
      if (prev == null) {
        return {
          id: masterCompanyId,
          label: "Master Company",
          division: e.target.value,
        };
      }
      return {
        ...prev,
        division: e.target.value,
      };
    });
  };
  const handleMasterCompanyLabelChange = async (e: BaseSyntheticEvent) => {
    setMasterCompanyItem((prev) => {
      if (prev == null) {
        return {
          id: masterCompanyId,
          label: e.target?.value,
          division: "",
        };
      }
      return {
        ...prev,
        label: e.target?.value,
      };
    });
  };
  const handleSelectAll = async (probable: boolean) => {
    let data = probable ? masters : searchMastersData;
    let selectedOrNot = probable ? selectedAll : searchSelectedAll;
    const length: number = data.length;
    if (selectedOrNot) {
      setSelectedState((prev) => {
        for (let index = 0; index < length; index++) {
          delete prev[data[index]?.id];
        }
        return prev;
      });
      return calculateCounts();
    }
    let isMasterCompanyPresent = false;
    setSelectedState((prev) => {
      for (let index = 0; index < length; index++) {
        if (data[index]?.id === masterCompanyId) {
          isMasterCompanyPresent = true;
          continue;
        }
        prev[data[index]?.id] = data[index];
      }
      return prev;
    });
    calculateCounts();
  };
  const handleSelectAllProbable = async () => {
    await handleSelectAll(true);
    setSelectedAll((prev) => !prev);
  };
  const handleSelectAllSearch = async () => {
    await handleSelectAll(false);
    setSearchSelectedAll((prev) => !prev);
  };
  return (
    <div style={{ backgroundColor: "white" }}>
      <div
        style={{
          margin: "0.5rem",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "0.5rem",
            gap: "1rem",
          }}
        >
          <div style={{ display: "flex", gap: "1rem", alignItems: "center" }}>
            <Typography>
              <b>Master Company :- </b>
            </Typography>
            <Input
              type="text"
              style={{ fontWeight: "bold" }}
              value={masterCompany?.label}
              onChange={handleMasterCompanyLabelChange}
              placeholder="Enter name for Master company"
            />
            <Typography>
              <b>({masterCompany?.id})</b>
            </Typography>
            <Input
              type="text"
              style={{ fontWeight: "bold" }}
              placeholder="Division"
              onChange={handleMasterDivisionChange}
              value={masterCompany?.division}
            />
          </div>
          <div style={{ display: "flex", gap: "1rem" }}>
            <Typography>
              <b>Overall Selected Companies :- </b>
            </Typography>
            <Typography>
              <b>{Object.keys(selectedState).length}</b>
            </Typography>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            gap: "1rem",
            marginBottom: "0.5rem",
          }}
        >
          <div style={{ display: "flex", gap: "2rem" }}>
            <Typography>
              <b>Total Count : {totalProbableCount}</b>
            </Typography>
            <Typography>
              <b>Selected Count : {totalProbableSelected}</b>
            </Typography>
          </div>
          <div style={{ display: "flex", gap: "2rem" }}>
            <Typography>
              <b>Total Count : {totalSearchCount}</b>
            </Typography>
            <Typography>
              <b>Selected Count : {totalSearchSelected}</b>
            </Typography>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            gap: "1rem",
          }}
        >
          <CustomBtnFilled
            label={selectedAll ? "Un-Select all" : "Select all"}
            variant="contained"
            onClick={handleSelectAllProbable}
          />
          <CustomBtnFilled
            label={searchSelectedAll ? "Un-Select all" : "Select all"}
            variant="contained"
            onClick={handleSelectAllSearch}
          />
        </div>
      </div>
      <div className={styles.pageContainer}>
        <div className={styles.leftSide}>
          <h1 style={{fontFamily: "Arial sans-serif"}}>Probable List of duplicates</h1>
          {loading && (
            <CircularProgress
              style={{ marginTop: "1rem", marginLeft: "1rem" }}
            />
          )}
          {!loading && error && <Typography color="error">{error}</Typography>}
          <FormControl>
            <FormGroup>
              {!loading &&
                !error &&
                masters &&
                masters.map((item) => {
                  if (masterCompanyId == item.id) {
                    if (masters.length == 1) {
                      setError(API_RESPONSES.NO_COMPANIES_FOUND);
                    }
                    return;
                  }
                  return (
                    <div
                      style={{
                        display: "flex",
                        gap: "0.25rem",
                        alignItems: "center",
                      }}
                      key={item.id}
                    >
                      <FormControlLabel
                        control={
                          <Checkbox
                            key={item.id}
                            checked={checkedOrNot(item)}
                            onChange={(e) => {
                              handleCheckedChange(e, item.id, item, true);
                            }}
                            name={item.label}
                          />
                        }
                        label={`${item.label} - (${item.id})`}
                      />
                      {checkedOrNot(item) && (
                        <FormControl>
                          <Input
                            type="text"
                            style={{ fontWeight: "bold" }}
                            placeholder="Division"
                            value={selectedState[item?.id]?.division}
                            onChange={(e) => {
                              handleDivisionChange(e, item);
                            }}
                          />
                        </FormControl>
                      )}
                    </div>
                  );
                })}
            </FormGroup>
          </FormControl>
        </div>
        <div className={styles.rightSide}>
          <h2 style={{fontFamily: "Arial sans-serif"}}>Select Duplicate Companies</h2>
          <SearchBar
            placeholder="Search Master Company"
            onSearch={handleSearch}
            searchValue={""}
          />
          {searchLoading && (
            <CircularProgress
              style={{ marginTop: "1rem", marginLeft: "1rem" }}
            />
          )}
          {!searchLoading && searchError && (
            <Typography color="error">{searchError}</Typography>
          )}
          <FormControl>
            <FormGroup>
              {!searchLoading &&
                !searchError &&
                searchMastersData &&
                searchMastersData.map((item) => {
                  if (masterCompanyId == item.id) {
                    if (searchMastersData.length == 1) {
                      setSearchError(API_RESPONSES.NO_COMPANIES_FOUND);
                      setTotalResults(0);
                    } else {
                      setTotalResults(searchMastersData.length - 1);
                    }
                    return;
                  }
                  return (
                    <div
                      style={{
                        display: "flex",
                        gap: "0.25rem",
                        alignItems: "center",
                      }}
                      key={item.id}
                    >
                      <FormControlLabel
                        control={
                          <Checkbox
                            key={item.id}
                            checked={checkedOrNot(item)}
                            onChange={(e) => {
                              handleCheckedChange(e, item.id, item);
                            }}
                            name={item.label}
                          />
                        }
                        label={`${item.label} - (${item.id})`}
                      />
                      {checkedOrNot(item) && (
                        <FormControl>
                          <Input
                            id={"divBox-" + item?.id.toString()}
                            name={"divBox-" + item?.id.toString()}
                            type="text"
                            style={{ fontWeight: "bold" }}
                            placeholder="Division"
                            value={selectedState[item?.id]?.division}
                            onChange={(e) => {
                              handleDivisionChange(e, item);
                            }}
                          />
                        </FormControl>
                      )}
                    </div>
                  );
                })}
            </FormGroup>
          </FormControl>
        </div>
      </div>
    </div>
  );
};
