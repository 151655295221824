import { AccountBalanceTwoTone as AccountBalanceTwoToneIcon, ContentCopyOutlined as ContentCopyOutlinedIcon, EditOutlined, Timeline } from '@mui/icons-material';
import { Box, Grid, Stack, Typography } from '@mui/material';
import { useSnackbar } from "notistack";
import { useState } from 'react';
import { AppRoutes } from "../../constants";
import { handleCopy } from "../../molecules/expert-profile-sections/profile-section/helper";
import { AddToProject } from "../../organisms/expert-cards/add-to-project";
import AddToStaging from "../../organisms/experts/add-to-staging";
import { selectedCardsTypes } from "../../pages/Experts/types";
import { isSuperAdmin } from "../../utils/role";
import TooltipIcons from '../project-card-footer-icons';
import { NumericData } from "./expert-call-detail-tooltip";

export default function ExpertsCountsandActions(props: any) {
  const validStatuses = ["Identified", "Contacted", "Onboarding"];
  const {
    id,
    name,
    status,
    editExpertClickHandler,
    handleOpenProfileLink,
    calls_data,
    openTimeline,
  } = props;
  const { enqueueSnackbar } = useSnackbar();
  const parsedUrl = new URL(window.location.href);
  const baseUrl = parsedUrl.origin;
  const fullBankAddURL = `${baseUrl}/layout/expert-profile?id=${id}&add_bank=1`;
  const [addToProjectOpen, setAddToProjectOpen] = useState(false);
  const [addToSEOpen, setAddToSEOpen] = useState(false);
  const [selectedExpert, setSelectedExpert] = useState({ label: props.name, value: props.id });
  const callCount = calls_data?.callCount ?? 0;
  const projectCount = calls_data?.projectCount ?? 0;
  const badCallCount = calls_data?.badCallCount ?? 0;
  const handleStageSubmit = (res: any) => {
    console.log("Submit:", res);
  };
  const handleOpenAddExpert = () => {
    setAddToProjectOpen(true);
  };


  const handleCloseAddToProject = () => {
    setAddToProjectOpen(false);
  };
  const handleOpenSE = () => {
    setAddToSEOpen(true);
  };

  const handleCloseSE = () => {
    setAddToSEOpen(false);
  };
  const selectedCards: selectedCardsTypes = [selectedExpert];
  return (
    <>
      {status === 'Confirmed' && (
        <div className="card-padding">
          <Grid container spacing={0} justifyContent="space-between" paddingTop={0} marginBottom={0} marginTop={0}>
            <Grid item xs={4}>
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                sx={{
                  padding: '10px',
                  width: '100%',
                  height: 'auto',
                  justifyContent: 'center',
                }}
              >
                <NumericData
                  value={callCount}
                  valueStyle={{ fontSize: '16px', color: '#EC9324', fontWeight: 500 }}
                  isLink
                  expert_id={id}
                  expert_name={name}
                  textDecoration="none"
                  valueClass=""
                />
                <Typography variant="caption" color="#000000DE" noWrap>
                  Calls
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={4}>
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                sx={{
                  padding: '10px',
                  width: '100%',
                  height: 'auto',
                  justifyContent: 'center'
                }}
              >
                <NumericData
                  value={projectCount}
                  isLink={projectCount && projectCount > 0}
                  link={AppRoutes.PROJECTS + "/all" + "?page=1" + "&projectIds=" + calls_data?.projects}
                  valueStyle={{ fontSize: '16px', color: '#EC9324', fontWeight: 500 }}
                  textDecoration="none"
                  valueClass=""
                />
                <Typography variant="caption" color="#000000DE" noWrap>
                  Projects
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={4}>
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                sx={{
                  padding: '10px',
                  width: '100%',
                  height: 'auto',
                  justifyContent: 'center'
                }}
              >
                <NumericData
                  value={badCallCount}
                  valueStyle={{ fontSize: '16px', color: '#EC9324', fontWeight: 500 }}
                  valueClass=""
                />
                <Typography variant="caption" color="#000000DE" noWrap>
                  Bad Calls
                </Typography>
              </Box>
            </Grid>
          </Grid>
          <div className="separator" />
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            // className="project-card-footer-icons"
            sx={{
              marginBottom: "-35px",
              overflow: 'hidden',
              flexWrap: 'nowrap',
              maxWidth: '100%',
              paddingTop: '10px',

              '& .MuiIconButton-root svg': {
                fontSize: "1.25rem",
                color: "rgba(0, 0, 0, 0.54)",

              },
              '& p': {
                fontSize: "12px",
                color: "rgba(0, 0, 0, 0.54)",
                fontWeight: 600,
              },
            }}
          >
            {(isSuperAdmin() || validStatuses.includes(status)) && (
              <TooltipIcons
                isIcon={false}
                isMaterialIcon={true}
                MaterialIcon={EditOutlined}
                title="Edit Expert"
                handleClick={() =>
                  editExpertClickHandler(id)
                }
              />
            )}

            <TooltipIcons
              isIcon={false}
              isMaterialIcon={true}
              MaterialIcon={Timeline}
              title="Timeline"
              handleClick={() =>
                openTimeline(id)
              }
            />

            <TooltipIcons
              isIcon={false}
              isMaterialIcon={true}
              MaterialIcon={AccountBalanceTwoToneIcon}
              title="Bank Details"
              handleClick={() =>
                handleCopy(fullBankAddURL, enqueueSnackbar, "Bank Form Link")
              }
            />

            {status === "Confirmed" && (
              <TooltipIcons
                isIcon={false}
                text="PE+"
                title="PE+"
                handleClick={handleOpenAddExpert}
              />
            )}

            {status === "Confirmed" && (
              <TooltipIcons
                isIcon={false}
                text="SE+"
                title="SE+"
                handleClick={handleOpenSE}
              />
            )}
            {status === "Confirmed" &&
              <TooltipIcons
                isIcon={false}
                isMaterialIcon={true}
                MaterialIcon={ContentCopyOutlinedIcon}
                title="Copy Profile Link"
                handleClick={async () =>
                  await handleOpenProfileLink(id)
                }
              />
            }
          </Stack>

          {addToSEOpen && (
            <AddToStaging
              isOpen={addToSEOpen}
              handleClose={handleCloseSE}
              handleStageSubmit={handleStageSubmit}
              selectedCards={selectedCards}
            />
          )}
          <AddToProject
            isOpen={addToProjectOpen}
            handleClose={handleCloseAddToProject}
            handleFormChange={() => { }}
            handleSubmitClose={handleCloseAddToProject}
            selectedExpert={selectedExpert}
          />
        </div>
      )}
    </>
  );
};


