import { Grid, Typography } from "@mui/material";
import CardsLoadingScreen from "../../atoms/cardsLoader";
import { APIRoutes, AppRoutes } from "../../constants";
import VerifyManualCard from "../../molecules/manual-verification/verifyManualCard";
import { useFetch } from "../../utils/hooks/useFetch";
import { BankAccountValue } from "../payment-requests/types";
import NoResultFoundFilters from "../../atoms/noResultsFilters";
import PaginationComponent from "../../atoms/pagination";
import { useNavigate } from "react-router-dom";
import { getFormattedData, rowsPerPage } from "./helper";
import { useGetParams } from "../../utils/hooks/useGetParams";
import { getManualVerifyFilterUrl } from "../../molecules/app-bars/manual-verify";

export default function VerifyManualCards() {
  const page = useGetParams("page") || "1";
  const expert_id = useGetParams("expert_id");
  const doc_type = useGetParams("doc_type"); 
  const {
    formattedData: data,
    loading,
    pagination: paginationData,
    refetch,
  } = useFetch<BankAccountValue[],any>(
    APIRoutes.bankDetails +
      "?equalto___manual_verification=1&limit=9&embed=YES&page=" +
      page +
      (expert_id ? `&fk_expert=${expert_id}`: "") + (doc_type ? `&document_type=${doc_type}` : ""),{
        async asyncFormatter(data) {
         return await getFormattedData(data);
        },
      }
  );
  const navigate = useNavigate();

  const paginationUrl = (page: string) =>
    getManualVerifyFilterUrl(expert_id,doc_type || "",page);

  function paginationHandler(e: any, value: any) {
    navigate(paginationUrl(value));
  }

  const isFilterApplied = expert_id || doc_type;

  return (
    <>
      {data ? (
        <>
          {data.length > 0 ? (
            <>
              <Grid container spacing={2}>
                {data?.map((data: any) => (
                  <Grid key={data.id} item xs={12} md={6} lg={4}>
                    <VerifyManualCard data={data} refetch={refetch} />
                  </Grid>
                ))}
              </Grid>

              <PaginationComponent
                page={paginationData?.page || 1}
                totalResult={paginationData?.total || 0}
                totalPages={paginationData.totalPages || 0}
                paginationHandler={paginationHandler}
                dropdownFilterProps={{
                  link: paginationUrl("1"),
                  setFilterPayload: (page) => {
                    console.log({page});
                  },
                  dropDownItems: rowsPerPage,
                  filterValue: "9",
                }}
                hideRowsPerPage
              />
            </>
          ) : (
            <>
              {!isFilterApplied ? (
                <Grid container spacing={2}>
                  <Grid
                    item
                    xs={12}
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"center"}
                    mt={4}
                  >
                    <Typography
                      variant="h2"
                      sx={{ fontSize: "20px", fontWeight: "500" }}
                    >
                      No document is pending for verification :-)
                    </Typography>
                  </Grid>
                </Grid>
              ) : (
                <NoResultFoundFilters />
              )}
            </>
          )}
        </>
      ) : (
        <CardsLoadingScreen />
      )}
    </>
  );
}
