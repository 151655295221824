import { FormProvider, useForm } from "react-hook-form";
import { Grid } from "@mui/material";
import CustomBtnFilled from "../../../atoms/form-molecules/CustomBtnFilled";
import styles from "../style.module.scss";
import CountryDetails from "../country-details";
import { emptyDefaultValues, submitIndiaForm, submitUsForm } from "../helper";
import { SubmitHandler } from "react-hook-form";
import React, { useState } from "react";
import FullDetailsForm from "../full-details-form";
import { useLocation } from "react-router-dom";
import { useSnackbar } from "notistack";
import { useBankContext } from "../context";
import { removeWhiteSpacesFromForm } from "../../../utils/utils";


type Props = {
  refresh: (() => Promise<void>) | (() => void);
  handleClose(): void;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  isEdit?: boolean;
  formDefaultValues?: any;
  showMainPageValue?: boolean;
  expert_id?: string;
}

export default function BankDetailsForm(props: Props) {
  const defaultValues = ("formDefaultValues" in props && props.isEdit) ? props.formDefaultValues : emptyDefaultValues;
  const methods = useForm({ defaultValues });
  const [showMainPage, setShowMainPage] = useState(props.showMainPageValue);
  const { msmeFileUrl , docVerification, panUrl, wForm, setWForm} = useBankContext();
  const { enqueueSnackbar } = useSnackbar();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const id = props.expert_id || queryParams.get("id");

  const onSubmit: SubmitHandler<any> = async (formData: any) => {
    const newFormData = removeWhiteSpacesFromForm(formData, [])
    if (!id) {
      enqueueSnackbar("Expert ID not found", {
        variant: "warning",
      });
      return;
    }

    if (props.isEdit && !props.formDefaultValues?.id) {
      enqueueSnackbar("Bank ID not found", {
        variant: "warning",
      });
      return;
    }

    const indian_bank_account = formData.bank_country === "India";
    const usa_bank_account = formData.bank_country === "United States of America";
    const other_bank_accounts = !indian_bank_account && !usa_bank_account;

    if (!showMainPage) {
      setShowMainPage(true);
    } else {
      if(indian_bank_account && docVerification.state === "invalid_doc" && formData.invalid_doc !== "consent_for_higher_tds") {
        enqueueSnackbar("Please accept the TDS deduction terms or update your PAN", {
          variant: "warning"
        })
        return;
      }
  
      if(indian_bank_account && (docVerification.state === "invalid_holder_name" || docVerification?.state === "manual_verify_rejected") && formData.invalid_holder_name === "pan_manual_verification" && !panUrl) {
        enqueueSnackbar("Please upload the PAN for manual verification", {
          variant: "warning"
        })
        return;
      }
  
      if(indian_bank_account && (docVerification.state === "invalid_holder_name" || docVerification?.state === "manual_verify_rejected") && !formData.invalid_holder_name) {
        enqueueSnackbar("Please upload your PAN for manual verification or provide consent for the higher TDS deduction.", {variant: "warning"});
        return;
      } 

      if(usa_bank_account && !wForm.w9) {
        enqueueSnackbar("Please upload W-9 Form", {variant: "warning"});
        return;
      } 

      if(!indian_bank_account && !formData.individual_option) {
        enqueueSnackbar("Kindly choose whether this bank account belongs to an individual or not.", {variant: "warning"});
        return;
      }

      if(other_bank_accounts && !wForm.W8_BEN_url && formData.individual_option === "yes") {
        enqueueSnackbar("Please upload Form W-8BEN", {variant: "warning"});
        return;
      } 
      
      if(other_bank_accounts && !wForm.W8_BEN_E_url && formData.individual_option === "no") {
        enqueueSnackbar("Please upload Form W-8BEN-E", {variant: "warning"});
        return;
      } 

      if(!indian_bank_account && !formData.check_1) {
        enqueueSnackbar("Please confirm you'll update the form if circumstances change.", {variant: "warning"});
        return;
      }

      // If form is Edit Form
      // If individual_option is changed
      // We want user to upload new W-9 Form
      if(props.isEdit && defaultValues.individual_option !== formData.individual_option && defaultValues?.w9_form_url === wForm.w9) {
        enqueueSnackbar("Please upload a new W-9 Form", {variant: "warning"});
        return;
      }

      if(docVerification.state === "manual_verify_rejected") {
        const doc_type = indian_bank_account ? "PAN" : usa_bank_account ? "W-9 Form" : "W-8BEN or W-8BEN-E" 
        const value = "Please Upload new " + doc_type;
        enqueueSnackbar(value,{variant: "warning"});
        return;
      }
      
      try {
        let response;
        props.setLoading(true);
        if (newFormData.bank_country === "India") {
          response = await submitIndiaForm(newFormData, parseInt(id), !!props.isEdit, props.formDefaultValues?.id, msmeFileUrl,docVerification,panUrl);
        } else {
          response = await submitUsForm(newFormData, parseInt(id), !!props.isEdit, props.formDefaultValues?.id,wForm,docVerification);
        }

        if (response.success) {
          enqueueSnackbar(props.isEdit ? response.message : "Added Bank Account Successfully.", {
            variant: "success",
          });
          setWForm({w9: null, W8_BEN_url: null, W8_BEN_E_url: null});
          await props.refresh();
          props.handleClose();
        } else {
          console.log({ response });
          if (response.error) {
            enqueueSnackbar(response?.error, { variant: "warning" });
          }

          if (response?.message) {
            enqueueSnackbar(response?.message?.toString(), {
              variant: "warning",
            });
          }
        }
        props.setLoading(false);
      } catch (err) {
        console.error({ err });
        enqueueSnackbar("Request failed.", { variant: "error" });
        props.setLoading(false);
      }
    }
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)} noValidate>
        <Grid className={styles.formContainer} container>
          {showMainPage ? <FullDetailsForm isEdit={!!props.isEdit} individual_option={defaultValues.individual_option} /> : <CountryDetails />}

          <Grid className={styles.actionRow} item xs={12}>
            {showMainPage && !props.isEdit ? (
              <CustomBtnFilled
                label="back"
                variant="outlined"
                onClick={() => {
                  setShowMainPage(false);
                }}
              />
            ) : (
              <CustomBtnFilled
                label="cancel"
                variant="outlined"
                onClick={props.handleClose}
              />
            )}

            <CustomBtnFilled
              label={showMainPage ? "submit" : "Proceed"}
              variant="contained"
              buttonType="submit"
            />
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
}
