import { RequestServer } from "../../../utils/services";
import { APIRoutes } from "../../../constants";
import { MasterItem , removeRecentResponseType, SearchResultType } from "../interfaces";
import {API_RESPONSES} from "../MastersCompany.constants";

function formatDate(date: any , endOfDate:boolean): string {
  const year = date?.$y;
  const month = String(date?.$M + 1).padStart(2, '0'); // Months are 0-indexed
  const day = String(date?.$D).padStart(2, '0');
  let time = "00:00:00";
  if(endOfDate){
    time = "23:59:59";
  }
  return `${year}-${month}-${day}T${time}`;
}

function parseUrl(selectDate : any , type:string , applyFilter:boolean): string {
  let url = `${APIRoutes.MASTERS_URL}?type=${type}&limit=200`;
  if(applyFilter ){ 
    switch (selectDate?.select) {
      case "between":
        url = url.concat(`&created_at_from=${formatDate(selectDate?.date[0] , false)}&created_at_to=${formatDate(selectDate?.date[1] , true)}`);
        break;
      case "before":
        url = url.concat(`&created_at_to=${formatDate(selectDate?.singleDate , true)}`);
        break;
      case "on":
        url = url.concat(`&created_at_from=${formatDate(selectDate?.singleDate , false)}&created_at_to=${formatDate(selectDate?.singleDate , true)}`);
        break;
      case "after":
        url = url.concat(`&created_at_from=${formatDate(selectDate?.singleDate , false)}`);
        break;
      }
  }
  return url;
}

export const fetchMastersList = async (type: string , selectDate:any , applyFilter:boolean): Promise<MasterItem[]> => {
  try {
    const response = await RequestServer(
      parseUrl(selectDate , type , applyFilter),
      "GET"
    );

    if (response?.success && Array.isArray(response.data?.data)) {
      return response.data.data;
    }
    throw new Error(API_RESPONSES.INVALID_API_RESPONSE);
  } catch (error: any) {
    return [];
  }
};

export const removeFromRecent = async (id: number):Promise<removeRecentResponseType> => {
  try{
    const response = await RequestServer(APIRoutes.MASTERS_REMOVE_FROM_RECENT , "PATCH" , {id : id});
    if(response?.success){
      return {success:true,message:API_RESPONSES.SUCCESSFULLY_REMOVED_FROM_RECENT};
    }
    throw new Error(response?.message || API_RESPONSES.ERROR_REMOVING_FROM_RECENT);
  }catch (error: any) {
    return {success:false,message:error.message};
  }
}

export const fetchCompanySearchList = async (searchParams: string , page:number = 1 , rowsPerPage: number = 100): Promise<SearchResultType> => {
  if(searchParams.trim() === ""){
    return {success:false,message:API_RESPONSES.NO_COMPANIES_FOUND};
  }
  try {
    const response = await RequestServer(
      `${APIRoutes.MASTERS_SEARCH}?search=${searchParams}&page=${page}&limit=${rowsPerPage}`,
      "GET"
    );
    if (response?.success && Array.isArray(response.data?.data)) {
      return {
        data:response?.data?.data ,
        success:true ,
        total:response?.data?.total ,
        page:response?.data?.page ,
        limit:response?.data?.limit ,
        message:API_RESPONSES.SUCCESSFULLY_SEARCHED};
    }
    throw new Error(API_RESPONSES.INVALID_API_RESPONSE);
  } catch (error: any) {
    return {success:false,message:error.message};
  }
};