import React, { useState, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import AppBarCommon from "../../molecules/app-bar-common";
import PaginationComponent from "../../atoms/pagination";
import { quickSearch } from "../../organisms/quick-search/helper";
import IndividualCards from "../../atoms/IndividualExpertCards/IndividualCards";
import { AddToProject } from "../../organisms/expert-cards/add-to-project";
import AddToStaging from "../../organisms/experts/add-to-staging";
import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";
import NavbarCommon from "../../molecules/nav-bar-common";
import { NavbarActions, NavbarActionsLength } from "./navbarActions";
import { AlertNBackdrop, selectedCardsTypes } from "../../../src/pages/Experts/types";
import { isSelected } from "../../common/select";
import { toggleItemInArray } from "../../common/select";
import AddToProjectExperts from "../../organisms/experts/map-multiple-experts-to-project/dialog";
import { handleSubmitClose } from "../Experts/helper";
import AfterStagingDialog from "../../organisms/experts/add-to-staging/afterStagingDialog";
import { SelectedAction } from "../compliance-approval-table/type";

type Props = {
  setFilterPayload?: (callback: (prev: any) => any) => void;
};

const QuickSearchPage: React.FC<Props> = ({ setFilterPayload = () => {} }) => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const pageFromParams = parseInt(searchParams.get("page") || "1", 10);
  const searchFromParams = searchParams.get("search") || "";
  const [selectExpert, setSelectExpert] = useState(false);
  const [selectedCards, setSelectedCards] = useState<selectedCardsTypes>([]);

  const [page, setPage] = useState(pageFromParams);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [search, setSearch] = useState(searchFromParams);
  const [totalPages, setTotalPages] = useState(1);
  const [totalResults, setTotalResults] = useState(0);
  const [data, setData] = useState<any[]>([]);
  const [addToProjectOpen, setAddToProjectOpen] = useState(false);
  const [addToSEOpen, setAddToSEOpen] = useState(false);
  const [selectedExpert, setSelectedExpert] = useState<{ label: string; value: number }>({ label: "", value: 0 });
  const [isLoading, setIsLoading] = useState(false);
  const [openStageShowDialog, setOpenStageShowDialog] = useState(false);
  const [stagingResponse, setStagingResponse] = useState({
    addedExperts: [],
    already_added_to_pe_table: [],
    already_staged_expert_ids: [],
    not_confirmed_expert_ids: [],
  });
  const [dialogs, setDialogs] = useState<any>({
    addToStaging: false,
    addToProject: false,
    timelineFilters: false,
    timeline: {
      state: false,
      data: null,
      messages: null,
      id: null,
    },
  });

  const [alertNBackdrop, setAlertNBackdrop] = useState<AlertNBackdrop>({
    alert: false,
    backdrop: false,
  });

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const result = await quickSearch(page, rowsPerPage, search);
      setData(result?.data || []);
      setTotalPages(result?.totalPages || 1);
      setTotalResults(result?.total || 0);
      setIsLoading(false);
    };
    fetchData();
  }, [page, search, rowsPerPage]);

  useEffect(() => {
    setSearchParams({ page: page.toString(), search });
  }, [page, search, setSearchParams]);

  const handleSearch = (text: string) => {
    setSearch(text);
    setPage(1);
    setFilterPayload((prev) => ({
      ...prev,
      searchFilter: text,
      isFilterChange: true,
    }));
  };

  const handlePageChange = (newPage: number) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (value: string) => {
    const newRowsPerPage = parseInt(value, 10);
    setRowsPerPage(newRowsPerPage);
    setPage(1);
  };
  const handleOpenAddExpert = (expert: any) => {
    setSelectedExpert({
      label: expert?.name,
      value: expert?.id,
    });
    setAddToProjectOpen(true);
  };

  const handleCloseAddToProject = () => {
    setAddToProjectOpen(false);
  };

  const handleOpenSE = (expert: any) => {
    setSelectedExpert({
      label: expert?.name,
      value: expert?.id,
    });
    setAddToSEOpen(true);
  };

  const handleCloseSE = () => {
    setAddToSEOpen(false);
  };

  const handleStageSubmit = (res: any) => {
    console.log("Submit:", res);
  };

  const addToProjectClickHandler = () => {
    setDialogs((prev: any) => ({
      ...prev,
      addToProject: true,
    }));
  };

  const addToStagingClickHandler = () => {
    setDialogs((prev: any) => ({
      ...prev,
      addToStaging: true,
    }));
  };

  const selectClickHandler = () => {
    setSelectExpert((prev) => !prev);
  };

  const [selectedAction, setSelectedAction] = useState<SelectedAction>(null);

  return (
    <>
      <Box sx={{ paddingRight: "15px", paddingLeft: "15px" }}>
        <AppBarCommon
          title="Quick Search - Experts"
          isSearch={true}
          searchLabel="Search"
          onSearch={handleSearch}
          isUserIcon
          isIconDefine={false}
          searchValue={search}
        />
        <NavbarCommon
          selectActionSubmitBtnName={"Select"}
          selectedAction={selectedAction}
          onActionSelect={(action) => setSelectedAction(action)}
          selectClickHandler={selectClickHandler}
          isSelectClicked={selectExpert}
          Actions={NavbarActions(addToProjectClickHandler, addToStagingClickHandler)}
          totalSelected={selectedCards.length}
          ActionsLength={NavbarActionsLength}
          isFilterApplied={false}
          resetFilters={() => {}}
          NavbarItems={<></>}
        />
        <div style={{ padding: "16px" }}>
          {search === "" ? (
            <p
              style={{
                textAlign: "center",
                color: "black",
                fontSize: "18px",
                fontWeight: "500",
                marginLeft: "29px",
              }}
            >
              Type in searchbar.....
            </p>
          ) : isLoading ? (
            <Skeleton variant="rectangular" width="100%" height={200} />
          ) : data.length > 0 ? (
            data.map((item) => (
              <IndividualCards
                selectExpert={selectExpert}
                selectedCards={selectedCards}
                setSelectedCards={setSelectedCards}
                totalSelected={selectedCards.length}
                key={item.id}
                page={page}
                search={search}
                name={item.name}
                id={item.id}
                primary_email={item.primary_email}
                primary_mobile={item.primary_mobile}
                approved_by_value={item.approved_by_value}
                highlights={item.highlight || {}}
                status={item.status}
                handleOpenAddExpert={() => handleOpenAddExpert(item)}
                handleOpenSE={() => {
                  handleOpenSE(item);
                }}
              />
            ))
          ) : (
            <p>No results found</p>
          )}
        </div>
        {(search !== "" && data.length > 0) && (
          <PaginationComponent
            page={page}
            totalPages={totalPages}
            totalResult={totalResults}
            paginationHandler={(e, newPage) => handlePageChange(newPage)}
            dropdownFilterProps={{
              link: "",
              setFilterPayload: handleRowsPerPageChange,
              dropDownItems: [
                { label: "25", value: "25" },
                { label: "50", value: "50" },
                { label: "75", value: "75" },
                { label: "100", value: "100" },
              ],
              filterValue: rowsPerPage.toString(),
            }}
          />
        )}

        <AddToProjectExperts
          isOpen={dialogs.addToProject}
          handleClose={() => {
            handleSubmitClose(setDialogs);
          }}
          selectedCards={selectedCards}
          setBackdrop={(bool: boolean) => {
            setAlertNBackdrop((prev) => ({ ...prev, backdrop: bool }));
          }}
        />

        <AddToStaging
          isOpen={dialogs.addToStaging}
          handleClose={() => {
            handleSubmitClose(setDialogs);
          }}
          selectedCards={selectedCards}
          handleStageSubmit={(response: any) => {
            handleStageSubmit(response);
          }}
        />

        <AfterStagingDialog
          stageResponse={stagingResponse}
          isOpen={openStageShowDialog}
          handleClose={() => {
            setOpenStageShowDialog(false);
          }}
          selectedCards={selectedCards}
        />

        {addToProjectOpen && (
          <AddToProject
            isOpen={addToProjectOpen}
            handleClose={handleCloseAddToProject}
            handleFormChange={() => {}}
            handleSubmitClose={handleCloseAddToProject}
            selectedExpert={selectedExpert}
          />
        )}

        {addToSEOpen && (
          <AddToStaging
            isOpen={addToSEOpen}
            handleClose={handleCloseSE}
            handleStageSubmit={handleStageSubmit}
            selectedCards={[selectedExpert]}
          />
        )}
      </Box>
    </>
  );
};

export default QuickSearchPage;
